import React from "react";
import LeftMenuButton from "../LeftMenuButton";

export default function MobilePagination({
  handleChangePage,
  setPreviewCount,
  previewCount,
  page,
  preview,
  book,
  FullScreen,
  setFullScreen
}) {
  const handleNextPage = () => {
    if (preview) {
      setPreviewCount(previewCount + 1);
    } else {
      if (page < book.pages.length) {
        handleChangePage(page + 1);
      }
    }
  };
  const handlePrevPage = () => {
    if (preview) {
      if (previewCount > 1) {
        setPreviewCount(previewCount - 1);
      }
    } else {
      if (page > 1) {
        handleChangePage(page - 1);
      }
    }
  };
  return (
    <div
      className="mobile-pagination"
      style={{
        position: "absolute",
        width: "100%",
        top: "50%",
        display: "flex",
        justifyContent: "space-between",
        color: "red",
        zIndex: "999999",
      }}
    >
      <span style={{ marginLeft: "10px" }}>
        <LeftMenuButton
          icon={{
            type: "link",
            icon: "/icons/ButtonBack.svg",
          }}
          handleClick={() => {
            handlePrevPage();
          }}
          styleCodes={{
            boxShadow: "0px 0px 10px 0px rgb(0 0 0 / 30%)",
          }}
        />
      </span>
      <span style={{ marginRight: "90px" }}>
        <LeftMenuButton
          icon={{
            type: "link",
            icon: "/icons/ButtonForward.svg",
          }}
          handleClick={() => {
            handleNextPage();
          }}
          styleCodes={{
            boxShadow: "0px 0px 10px 0px rgb(0 0 0 / 30%)",
          }}
        />
      </span>
    </div>
  );
}
