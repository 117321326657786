import React, { useState } from "react";
import ReactPlayer from "react-player";

export default function Answers({
  openAnswer,
  setOpenAnswer,
  referans,
  answerData,
}) {
  const [size, setSize] = useState(1);
  const [tab, setTab] = useState(1);
  return (
    <div
      ref={referans}
      className="position-absolute answer-popup"
      style={{
        display: openAnswer ? "block" : "none",
        minWidth: "500px",
        position: "absolute",
        top: "30px",
        left: "700px",
        backgroundColor: "white",
        color: "black",
        zIndex: 999999999999999,
        border: "2px solid #e6e6e6df",
        boxShadow: "0px 0px -7px 7px black",
      }}
    >
      <div
        className="head d-flex justify-content-between align-items-center"
        style={{
          padding: "10px 5px",
          backgroundColor: "white",
        }}
      >
        <div className="d-flex justify-content-start align-items-center">
          <span
            onClick={() => {
              if (size > 1) {
                setSize((prevstate) => prevstate - 1);
              }
            }}
            className="cursor-pointer"
          >
            <svg
              fill={size > 1 ? "orange" : "gray"}
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              width={"30px"}
              height={"30px"}
            >
              <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z"
              ></path>
            </svg>
          </span>
          <span
            onClick={() => {
              setSize((prevstate) => prevstate + 1);
            }}
            className="cursor-pointer"
          >
            <svg
              fill="green"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              width={"30px"}
              height={"30px"}
            >
              <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"
              ></path>
            </svg>
          </span>
          <h2 className="p-0 m-0">
            Cevap : {answerData?.answer?.toUpperCase()}
          </h2>
        </div>

        <span
          className="d-flex justify-content-center align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => setOpenAnswer(false)}
        >
          <svg fill="red" viewBox="0 0 24 24" width={30} height={30}>
            <path
              clipRule="evenodd"
              fillRule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
            ></path>
          </svg>
        </span>
      </div>
      <div className="answer-tab mb-2">
        <span
          className="cursor-pointer"
          style={{
            backgroundColor: tab === 1 && "#e6e6e6df",
            color: tab === 1 && "green",
          }}
          onClick={() => setTab(1)}
        >
          Resimli Çözüm
        </span>
        <span
          className="cursor-pointer"
          style={{
            backgroundColor: tab === 2 && "#e6e6e6df",
            color: tab === 2 && "green",
          }}
          onClick={() => setTab(2)}
        >
          Videolu Çözüm
        </span>
      </div>
      {tab === 1 && (
        <div
          style={{
            padding: "10px 5px",
            backgroundColor: "white",
            width: size * 100 + 400 + "px",
          }}
        >
          <img src={answerData?.image} alt="cevap" style={{ width: "100%" }} />
        </div>
      )}
      {tab === 2 && (
        <div
          style={{
            padding: "10px 5px",
            backgroundColor: "white",
            width: size * 100 + 400 + "px",
          }}
        >
          <ReactPlayer url={answerData.video} controls={true} width="100%" />
        </div>
      )}
    </div>
  );
}
