import axios from 'axios'

const Network = class NETWORK {
    constructor(axios) {
        this.network = axios.create({
            baseURL: 'https://service.akillitahta.net/z-kitap/'
            // baseURL: 'https://development.codelisa.com.tr/z-kitap/'
            // baseURL: 'http://127.0.0.1:8000/z-kitap/'
        })
        this.network.interceptors.request.use(async config => {
            config.headers.cid = '8f184a57-464a-49f0-a6ee-1516f28b4096'
            return config
        })
    }

    get = async path => {
        return await this.network.get(path).then(r => (r.data))
    }

    post = async (path, body, headers) => {
        return await this.network.post(path, body, { headers })
            .then(r => (r.data))
    }

    getFile = async path => {
        return await this.network.get(path, { responseType: 'blob' }).then(r => (r.data))
    }
}

export default new Network(axios)