/*
    Bir kitap, Download edildikten sonra Base64 formatına getirilir.
    IndexDatabase üzerine de tek tek yazılır.
*/

const BookImageToBase64 = async image => {
    const pageImage = await fetch(`https://service.akillitahta.net/proxy?url=${image}`)
    if (!pageImage.ok) return null
    const blob = await pageImage.blob()

    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
    })
}

export default BookImageToBase64