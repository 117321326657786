import React, { useState, useEffect } from "react";

const Timer = ({ close }) => {
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval = null;

    if (isActive) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const handleStart = () => {
    setIsActive((prevIsActive) => !prevIsActive);
  };

  const handlePause = () => {
    setIsActive(false);
  };

  const handleReset = () => {
    setIsActive(false);
    setSeconds(0);
  };

  const handleClose = () => {
    setIsActive(false);
    setSeconds(0);
    close();
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((time % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
    return `${hours} : ${minutes} : ${seconds}`;
  };

  return (
    <div
      className={"timer"}
      style={{ boxShadow: "0px 0px 10px 0px rgb(0 0 0 / 30%)" }}
    >
      <button
        className="timer-btn"
        onClick={() => {
          handleStart();
        }}
      >
        {isActive ? "Durdur" : "Başlat"}
      </button>
      <div
        style={{
          width: "40%",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <a style={{ fontWeight: "500" }}>{formatTime(seconds)}</a>
      </div>
      <button
        className="timer-btn"
        onClick={() => {
          handleReset();
        }}
      >
        Sıfırla
      </button>
      <button
        className="timer-btn-close"
        onClick={() => {
          handleClose();
        }}
      >
        X
      </button>
    </div>
  );
};

export default Timer;
