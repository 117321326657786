/*
    Bu alanda, Sunucu üzerinde bulunan kitapların indirilmesi
    ve bu kitapların Base64 formatına dönüştürülmesi işlemi yapılmaktadır.
*/

import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import Navbar from '../Components/Navbar'
import BookOneSkeleton from '../Components/BookOneSkeleton'
import BookOne from '../Components/BookOne'

import BookImageToBase64 from '../Helpers/BookImageToBase64'
import indexDatabase from '../Helpers/IndexDatabase'

import Network from '../Helpers/Network'
import Swal from 'sweetalert2'
import {saveAs} from 'file-saver'
import PdfToBase64 from '../Helpers/PdfToBase64'
import axios from 'axios'
import {pdfjs} from 'react-pdf'
import IndexDatabase from '../Helpers/IndexDatabase'

const HomeScreen = () => {
    const navigate = useNavigate();
    const skeletonTimes = [1, 2, 3, 4, 5, 6, 7, 8]

    const [list, setList] = useState([])
    const [ready, setReady] = useState(false)

    const [exportUi, setExportUi] = useState(false)
    const [downloadUi, setDownloadUi] = useState(false)
    const [totalPages, setTotalPages] = useState(0)
    const [processPages, setProcessPages] = useState(0)

    const [load, setLoad] = useState(0)

    const reloadWindow = () => {
        window.location.reload()
    }

    useEffect(() => {
        Network.get('book/list').then(r => {
            setList(r)
        }).finally(() => {
            setTimeout(() => {
                setReady(true)
            }, 500)
        })
    }, [])

    const startNavigate = async item => {
        await startDownload(item, true)
    }

    const startDownload = async (item, route = false) => {
        try {
            let i = 1
            const checkDatabase = await localStorage.getItem(`book-${item.id}`)
            if (checkDatabase) {
                const thisPages = await indexDatabase(`book-${item.id}`, 'pages', 'listKeys', '', '')
                if (thisPages && thisPages.length > 0) {
                    i = thisPages.length
                }
            } else {
                await localStorage.setItem(`book-${item.id}`, 'ok')
            }

            setDownloadUi(true)
            await indexDatabase(`book-${item.id}`, 'info', 'create', JSON.stringify(item), 'book')

            const url = item.url
            const file = await axios.get(url, { responseType: 'blob' }).then(r => (r.data))

            const cuts = await Network.post('cut/list', { bookId: item.id })

            const reader = new FileReader()
            let percentStart = i

            reader.onloadend = async () => {
                const pdf = await pdfjs.getDocument({data : reader.result}).promise
                const numPages = pdf.numPages
                setTotalPages(numPages)
                for (i; i <= numPages; i++) {
                    const page = await pdf.getPage(i)
                    const viewport = page.getViewport({scale : 5.0})
                    const canvas = document.createElement('canvas')
                    const context = canvas.getContext('2d')
                    canvas.height = viewport.height
                    canvas.width = viewport.width
                    await page.render({canvasContext : context, viewport : viewport}).promise;

                    // İlgili sayafaya ait Base64
                    const base64String = canvas.toDataURL()

                    // İlgili sayfaya ait Sorular
                    const thisQuestions = cuts.questions.filter(x => x.page === i)

                    // İlgili sayfaya ait Info Bilgiler
                    const thisInfos = cuts.infos.filter(x => x.page === i)


                    const dbObject = {
                        base64String,
                        thisQuestions,
                        thisInfos
                    }

                    await IndexDatabase(`book-${item.id}`, 'pages', 'create', JSON.stringify(dbObject), `page-${i}`)
                    setProcessPages(percentStart)
                    percentStart = percentStart + 1
                }
                if (route) {
                    navigate(`/board?book=${item.id}`)
                } else {
                    setDownloadUi(false)
                }
                setLoad(load + 1)
            }

            reader.readAsArrayBuffer(file)
        } catch (error) {
            console.log(error)
        }
    }

    const startBuild = async id => {
        try {
            setExportUi(true)
            // Index Database üzerinde yazılı bulunan Kitap, Export alınır.
            const thisBook = await indexDatabase('oneDetail', id)
            const blob = new Blob([thisBook], {type : 'application/json'})
            saveAs(blob, `book-${id}.json`)
            setTimeout(() => {
                setExportUi(false)
            }, 10000)
        } catch (error) {
            console.log(error)
        }
    }

    const startRemove = async id => {
        // Index Database üzerinden silinir.
        Swal.fire({
            title : 'Bir Saniye',
            text : 'Daha önce indirilmiş kitabı kaldıracak mısınız?',
            icon : 'warning',
            confirmButtonText : 'Evet',
            confirmButtonColor : '#9A0606',
            showCancelButton : true,
            cancelButtonText : 'Hayır',
            cancelButtonColor : '#242424'
        }).then(async (response) => {
            if (response.isConfirmed) {
                await localStorage.removeItem(`book-${id}`)
                indexDatabase(`book-${id}`, 'info', 'remove', {}, 'delete')
                setTimeout(() => {
                    window.location.reload()
                }, 1500)
            }
        });
    }

    return (
        <div className="container">
            <Navbar menu={'home'}/>
            <div className="row mt-5">
                {
                    !ready ?
                        skeletonTimes.map(item => {
                            return (
                                <div key={`book-${item}`}
                                     className="col-8 offset-2 col-sm-6 offset-sm-0 col-md-6 col-lg-4 col-xl-3">
                                    <BookOneSkeleton/>
                                </div>
                            )
                        })
                        :
                        list.map(item => {
                            return (
                                <div key={`book-${item.id}`}
                                     className="col-8 offset-2 col-sm-6 offset-sm-0 col-md-6 col-lg-4 col-xl-3">
                                    <BookOne
                                        book={item}
                                        download={() => {
                                            startDownload(item)
                                        }}
                                        build={() => {
                                            startBuild(item.id)
                                        }}
                                        remove={() => {
                                            startRemove(item.id)
                                        }}
                                        load={load}
                                        navigate={() => {
                                            startNavigate(item)
                                        }}
                                    />
                                </div>
                            )
                        })
                }
            </div>
            {
                downloadUi &&
                <div className={'download-ui'}>
                    <p>Kitap kullanıma hazırlanıyor.</p>
                    <label>Bu işlem internet hızınıza göre farklılık gösterebilir.</label>
                    <p>({totalPages} / {processPages}) Sayfalar İşleniyor.</p>
                    <button onClick={() => {
                        reloadWindow()
                    }}>
                        Çalışmayı Durdur
                    </button>
                </div>
            }
            {
                exportUi &&
                <div className={'download-ui'}>
                    <p>Kitap dışarı aktarılıyor.</p>
                    <label>Bu işlem bilgisayarınızın hızına göre değişkenlik gösterebilir.</label>
                    <p>Lütfen dosyanın sorunsuz şekilde bilgisayarınıza kayıt olmasını bekleyin.</p>
                </div>
            }
        </div>
    )
};

export default HomeScreen