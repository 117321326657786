import React, { useState } from "react";

const TrashComponent = ({ setCursorStyle, trashSizeChange, trashRadius }) => {
  const [trashes, setTrashes] = useState([
    25, 50, 75, 100, 125, 150, 175, 200, 225, 250,
  ]);
  return (
    <div className="trash-buttons d-flex align-items-center flex-wrap">
      {trashes.map((i) => {
        return (
          <img
            key={i}
            src={`/cursor-images/trash-cursor-${i}.png`}
            width={i / 5}
            height={i / 5}
            alt=""
            className="me-2 mb-2"
            style={{
              backgroundColor: trashRadius === i && `#000`,
              cursor: "pointer",
              borderRadius: "50%",
            }}
            onClick={() => {
              //   setCursorStyle(`cursor-eraser-${trashRadius}`);
              trashSizeChange(i);
            }}
          />
        );
      })}
    </div>
  );
};

export default TrashComponent;
