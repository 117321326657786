import arr035 from "../Assets/Icons/arr035.svg";
import arr071 from "../Assets/Icons/arr071.svg";
import arr074 from "../Assets/Icons/arr074.svg";
import Modal from "react-modal";
import React, { useEffect, useRef, useState } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import Timer from "./Timer";
import Buttons from "./Buttons";
import MenuButton from "./MenuButton";
import LeftMenuButton from "./LeftMenuButton";
import { Draggable } from "drag-react";
import PageNumber from "./PageNumber";
import Network from "../Helpers/Network";
import { useNavigate } from "react-router";
import { Form } from "react-bootstrap";
import RangeComponent from "./RangeComponent";
import TrashComponent from "./TrashComponent";

const Menu = ({
  preview,
  background,
  triggerBg,
  book,
  page,
  setPage,
  handleChangePage,
  maxPage,
  onNewPage = () => {},
  brushColorChange = (color) => {},
  brushSizeChange = (size) => {},
  brushRadius,
  trashSizeChange = (size) => {},
  trashRadius,
  onClear = () => {},
  onChangeMode = (mode) => {},
  closePreview = () => {},
  setLastQuestionNow = () => {},
  setPreviewCount = (index) => {},
  handlePageStatus = () => {},
  previewCount,
  questions = [],
  openAnswer,
  setOpenAnswer,
  FullScreen,
  setFullScreen,
  setAnswer,
  answer,
  setCursorStyle,
}) => {
  const navigate = useNavigate();
  const [Color, setColor] = useState("#000");
  const [Key, setKey] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [showShapes, setShowShapes] = useState(false);
  const [openTrashWidth, setOpenTrashWidth] = useState(false);
  const [showColorPalette, setShowColorPalette] = useState(false);
  const [showPageSelect, setShowPageSelect] = useState(false);
  const [timer, setTimer] = useState(false);
  const [closeTap, setCloseTab] = useState(false);
  const [currentNumber, setCurrentNumber] = useState("");
  const colorPaletteRef = useRef();
  const eraserPaletteRef = useRef();
  const menuRef = useRef();
  const shapeRef = useRef();
  const pageNumberRef = useRef(null);

  const colors = [
    "#ff0000",
    "#00ff00",
    "#0000ff",
    "#ffff00",
    "#ff00ff",
    "#00ffff",
    "#000000",
    "#ffffff",
  ];

  const handleFullScreen = () => {
    let element = document.documentElement;
    if (!FullScreen) {
      //   document.querySelector(".mobile-warn").style.display = "none";
      setFullScreen(true);
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }

    if (FullScreen) {
      setFullScreen(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };
  const handlePage = (c) => {
    if (Number(currentNumber) <= 0) {
      handleChangePage(0);
      setPreviewCount(0);
    } else if (Number(currentNumber) > book.pages.length) {
      handleChangePage(book.pages.length);
      setPreviewCount(book.pages.length);
    } else {
      handleChangePage(Number(currentNumber));
      setPreviewCount(Number(currentNumber));
    }
  };

  const handleNextPage = () => {
    if (preview) {
      setPreviewCount(previewCount + 1);
    } else {
      if (page < book.pages.length) {
        handleChangePage(page + 1);
      }
    }
  };
  const handlePrevPage = () => {
    if (preview) {
      if (previewCount > 1) {
        setPreviewCount(previewCount - 1);
      }
    } else {
      if (page > 1) {
        handleChangePage(page - 1);
      }
    }
  };

  const handleClickOutside = (event) => {
    const popups = document.querySelectorAll(".popup");
    const clickedInsidePopup = Array.from(popups).some((popup) =>
      popup.contains(event.target)
    );
    if (!clickedInsidePopup) {
      setKey("");
      // setOpenTrashWidth(false);
      // setShowShapes(false);
      // setOpenMenu(false);
      // setShowColorPalette(false);
    }
    if (
      colorPaletteRef.current &&
      !colorPaletteRef.current.contains(event.target)
    ) {
      setShowColorPalette(false);
    }
    if (
      eraserPaletteRef.current &&
      !eraserPaletteRef.current.contains(event.target)
    ) {
      setOpenTrashWidth(false);
    }
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenMenu(false);
    }
    if (shapeRef.current && !shapeRef.current.contains(event.target)) {
      setShowShapes(false);
    }
    if (
      pageNumberRef.current &&
      !pageNumberRef.current.contains(event.target)
    ) {
      setShowPageSelect(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const setImgSize = (img, width, height) => {
    img.set({
      width: width,
      height: height,
    });
  };

  const [selectBackground, setSelectBackground] = useState(false);
  const [bgs, setBgs] = useState([
    "thumb/bg-1-min.jpg",
    "thumb/bg-2-min.jpg",
    "thumb/bg-3-min.jpg",
    "thumb/bg-4-min.jpg",
    "thumb/bg-5-min.jpg",
    "thumb/bg-6-min.jpg",
    "thumb/bg-7-min.jpg",
    "thumb/bg-8-min.jpg",
    "thumb/bg-9-min.jpg",
    "thumb/bg-10-min.jpg",
    "thumb/bg-11-min.jpg",
    "thumb/bg-12-min.jpg",
    "thumb/bg-13-min.jpg",
    "thumb/bg-14-min.jpg",
    "thumb/bg-15-min.jpg",
    "thumb/bg-16-min.jpg",
    "thumb/bg-17-min.jpg",
    "thumb/bg-18-min.jpg",
    "thumb/bg-19-min.jpg",
    "thumb/bg-20-min.jpg",
    "thumb/bg-21-min.jpg",
    "thumb/bg-22-min.jpg",
    "thumb/bg-23-min.jpg",
    "thumb/bg-24-min.jpg",
    "thumb/bg-25-min.jpg",
    "thumb/bg-26-min.jpg",
    "thumb/bg-27-min.jpg",
    "thumb/bg-28-min.jpg",
    "thumb/bg-29-min.jpg",
    "thumb/bg-30-min.jpg",
    "thumb/bg-31-min.jpg",
  ]);
  const [active, setActive] = useState(999);

  const save = () => {
    localStorage.setItem("background", active + 1);
    setSelectBackground(false);
    triggerBg();
  };

  useEffect(() => {
    const findIndex = bgs.findIndex((x) => x === background);
    setActive(findIndex);
  }, [background]);

  const [openContents, setOpenContents] = useState(false);
  const [contentList, setContentList] = useState([]);

  const contentsHandler = async (bookId) => {
    await Network.post(`/insides/list`, { bookId }).then((res) => {
      console.log(res);
      setContentList(res);
    });
  };

  return (
    <>
      <div
        className={!FullScreen ? "mobile-warn" : "mobile-warn mobile-hidden"}
      >
        <p style={{ fontSize: "2rem" }}>
          Uygulamayı Mobil Ekranda kullanabilmeniz için tam ekran modunu
          seçmelisiniz
        </p>
        <button
          onClick={() => {
            handleFullScreen();
          }}
          style={{
            border: "none",
            backgroundColor: "orangered",
            padding: "7.5px 15px",
            fontSize: "1.2rem",
            color: "white",
          }}
        >
          Tam Ekran Modu
        </button>
      </div>
      <div className="left-menu">
        <div className="left-menu-wrapper">
          <div className="left-menu-wrapper-inside px-1">
            <div className="logo">
              <a href="/">
                <img src="/logo.svg" alt="logo" />
              </a>
            </div>
            <hr />
            <div className="left-menu-button-group">
              <div>
                <LeftMenuButton
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonHand.svg",
                  }}
                  handleClick={() => {
                    console.log("first");
                    setCursorStyle("cursor-pointer");
                    onChangeMode("move");
                  }}
                  iconWidth="44px"
                  iconHeight="44px"
                />

                <LeftMenuButton
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonEmptyPage.svg",
                  }}
                  handleClick={() => {
                    console.log("new Page");
                    onNewPage();
                  }}
                  iconWidth="44px"
                  iconHeight="44px"
                />
                <LeftMenuButton
                  icon={{
                    type: "link",
                    icon: arr035,
                  }}
                  handleClick={() => onChangeMode("drag")}
                  iconWidth="44px"
                  iconHeight="44px"
                />
              </div>
              <div>
                <LeftMenuButton
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonPen.svg",
                  }}
                  handleClick={() => {
                    onChangeMode("paint2");
                    setCursorStyle("cursor-pen");
                    setKey("pen2");
                    setShowColorPalette((prev) => !prev);
                  }}
                  iconWidth="44px"
                  iconHeight="44px"
                />
                <LeftMenuButton
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonMarker.svg",
                  }}
                  handleClick={() => {
                    onChangeMode("paint");
                    setCursorStyle("cursor-marker");
                    setKey("pen");
                    setShowColorPalette((prev) => !prev);
                  }}
                  iconWidth="44px"
                  iconHeight="44px"
                />
                {showColorPalette && (
                  <div className={"color-palette"} ref={colorPaletteRef}>
                    {colors.map((color, index) => (
                      <div
                        key={`color-palette-${index}`}
                        style={{
                          backgroundColor: color,
                          width: "18px",
                          height: "18px",
                          borderRadius: "18px",
                          display: "inline-block",
                          margin: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          brushColorChange(color);
                          setColor(color);
                        }}
                      />
                    ))}
                    <span className="brush-line popup">
                      <svg
                        version="1.0"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100px"
                        height="10.000000pt"
                        viewBox="0 0 94.000000 10.000000"
                        preserveAspectRatio="xMidYMid meet"
                        stroke={Color}
                      >
                        <g
                          transform="translate(0.000000,10.000000) scale(0.100000,-0.100000)"
                          fill={Color}
                          stroke={Color}
                          strokeWidth={brushRadius}
                        >
                          <path
                            d="M535 80 c-27 -5 -70 -17 -95 -26 -65 -23 -264 -30 -360 -11 -71 14
 -91 14 -78 1 3 -3 46 -12 95 -21 114 -20 247 -12 372 22 129 34 307 34 444 0
 15 -4 27 -3 27 3 0 27 -301 51 -405 32z"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <RangeComponent
                      onclick={brushSizeChange}
                      brushRadius={brushRadius}
                      borderColor={Color}
                    />
                    {/* <input
                      type="range"
                      min="1"
                      max="101"
                      defaultValue="20"
                      value={brushRadius}
                      step={20}
                      id="brush-line"
                      className="range range-000000"
                      style={{ width: "100%", backgroundColor: Color }}
                      onInput={(e) => {
                        brushSizeChange(parseInt(e.target.value));
                      }}
                    ></input> */}
                  </div>
                )}
              </div>
              <div>
                <LeftMenuButton
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonEraser.svg",
                  }}
                  handleClick={() => {
                    onChangeMode("trash");
                    setKey("trash");

                    setCursorStyle(`cursor-none`);
                    setOpenTrashWidth(!openTrashWidth);
                  }}
                  iconWidth="44px"
                  iconHeight="44px"
                />

                {openTrashWidth && (
                  <label
                    className={`eraser-palette popup ${
                      Key === "trash" && "active"
                    }`}
                    ref={eraserPaletteRef}
                  >
                    {/* <span className="trash-width">
                      <svg
                        version="1.0"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100px"
                        height="10.000000pt"
                        viewBox="0 0 94.000000 10.000000"
                        preserveAspectRatio="xMidYMid meet"
                        stroke="#000"
                      >
                        <g
                          transform="translate(0.000000,10.000000) scale(0.100000,-0.100000)"
                          strokeWidth={trashRadius / 10}
                        >
                          <path
                            d="M535 80 c-27 -5 -70 -17 -95 -26 -65 -23 -264 -30 -360 -11 -71 14
 -91 14 -78 1 3 -3 46 -12 95 -21 114 -20 247 -12 372 22 129 34 307 34 444 0
 15 -4 27 -3 27 3 0 27 -301 51 -405 32z"
                          ></path>
                        </g>
                      </svg>
                    </span> */}
                    <TrashComponent
                      setCursorStyle={setCursorStyle}
                      trashSizeChange={trashSizeChange}
                      trashRadius={trashRadius}
                    />

                    {/* <input
                      type="range"
                      min="0"
                      max="250"
                      defaultValue="0"
                      step={25}
                      value={trashRadius}
                      style={{ width: "100%" }}
                      onInput={(e) => {
                        setCursorStyle(`cursor-eraser-${trashRadius}`);
                        trashSizeChange(parseInt(e.target.value));
                      }}
                    ></input> */}
                  </label>
                )}
              </div>
              <div>
                <LeftMenuButton
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonShapes.svg",
                  }}
                  handleClick={() => {
                    setShowShapes(!showShapes);
                  }}
                  iconWidth="44px"
                  iconHeight="44px"
                />
                {showShapes && (
                  <div
                    className={
                      "shapes-palette d-flex justify-content-between align-items-center flex-wrap"
                    }
                    ref={shapeRef}
                  >
                    <LeftMenuButton
                      icon={{
                        type: "link",
                        icon: "/icons/line.svg",
                      }}
                      handleClick={() => onChangeMode("line")}
                    />
                    <LeftMenuButton
                      icon={{
                        type: "link",
                        icon: "/icons/rectangle.svg",
                      }}
                      handleClick={() => onChangeMode("rect")}
                    />
                    <LeftMenuButton
                      icon={{
                        type: "link",
                        icon: "/icons/circle.svg",
                      }}
                      handleClick={() => onChangeMode("circle")}
                    />
                    <LeftMenuButton
                      icon={{
                        type: "link",
                        icon: "/icons/triangle.svg",
                      }}
                      handleClick={() => onChangeMode("triangle")}
                    />
                  </div>
                )}
              </div>
              <div>
                <LeftMenuButton
                  id={"silmeTusu"}
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonClearAll.svg",
                  }}
                  handleClick={() => {
                    setCursorStyle("cursor-default");
                    onChangeMode("clear");
                  }}
                  iconWidth="44px"
                  iconHeight="44px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tab-close-wrapper" id="tab-close-wrapper">
        <div className="tab-close">
          <div>
            {!closeTap ? (
              <svg
                width={26}
                height={26}
                viewBox="0 0 30 30"
                style={{ transform: "rotate(-90deg)", cursor: "pointer" }}
                onClick={() => {
                  document
                    .getElementById("toolbar")
                    .classList.toggle("hidden-toolbar");
                  document.getElementById("tab-close-wrapper").style.bottom = 0;
                  setCloseTab(true);
                }}
              >
                <g>
                  <g>
                    <path
                      d="M11.5,15l8.63-7.88a.76.76,0,0,0,0-1.09A.77.77,0,0,0,19,6L9.86,14.42a.87.87,0,0,0,0,1.16L19,24a.77.77,0,0,0,1.09,0,.76.76,0,0,0,0-1.09Z"
                      style={{ fill: "#4d4d4d" }}
                    />
                  </g>
                </g>
              </svg>
            ) : (
              <svg
                width={26}
                height={26}
                viewBox="0 0 30 30"
                style={{ transform: "rotate(90deg)", cursor: "pointer" }}
                onClick={() => {
                  document
                    .getElementById("toolbar")
                    .classList.toggle("hidden-toolbar");
                  document.getElementById("tab-close-wrapper").style.bottom =
                    "60px";
                  setCloseTab(false);
                }}
              >
                <g>
                  <g>
                    <path
                      d="M11.5,15l8.63-7.88a.76.76,0,0,0,0-1.09A.77.77,0,0,0,19,6L9.86,14.42a.87.87,0,0,0,0,1.16L19,24a.77.77,0,0,0,1.09,0,.76.76,0,0,0,0-1.09Z"
                      style={{ fill: "#4d4d4d" }}
                    />
                  </g>
                </g>
              </svg>
            )}
          </div>
        </div>
      </div>
      {/* {Key !== "book" && (
        <div className="tools tools-left-absolute">
          <div className="options popup tools-left-button-group">
            <button
              data-tooltip-id="tooltip-left"
              data-tooltip-content="Önceki Sayfa"
              className="light"
              onClick={() => {
                handlePrevPage();
              }}
              style={{ boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 10px 0px" }}
            >
              <img src={arr074} alt="arr074" />
            </button>

            <button
              data-tooltip-id="tooltip-left"
              data-tooltip-content="Sonraki Sayfa"
              className="light"
              onClick={() => {
                handleNextPage();
              }}
              style={{ boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 10px 0px" }}
            >
              <img src={arr071} alt="arr071" />
            </button>
          </div>
        </div>
      )} */}

      {/* Right Page Change*/}
      {Key !== "book" && (
        <div className="tools tools-right-absolute">
          <div className="options popup tools-left-button-group">
            <button
              data-tooltip-id="tooltip-left"
              data-tooltip-content="Önceki Sayfa"
              className="light"
              onClick={() => {
                handlePrevPage();
              }}
              style={{ boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 10px 0px" }}
            >
              <img src={arr074} alt="arr074" />
            </button>

            <button
              data-tooltip-id="tooltip-left"
              data-tooltip-content="Sonraki Sayfa"
              className="light"
              onClick={() => {
                handleNextPage();
              }}
              style={{ boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 10px 0px" }}
            >
              <img src={arr071} alt="arr071" />
            </button>
          </div>
        </div>
      )}

      <div className={"toolbar-container"} id="toolbar">
        <Tooltip id="tooltip-left" />
        <Tooltip id="tooltip-page" />
        <Tooltip id="tooltip-right" />
        <Tooltip id="tooltip-color" />
        <Tooltip id="tooltip-pen" />
        <Tooltip id="tooltip-blush" />
        <Tooltip id="tooltip-remove" />
        <Tooltip id="tooltip-kare" />
        <Tooltip id="tooltip-circle" />
        <Tooltip id="tooltip-ucgen" />
        <Tooltip id="tooltip-line" />
        <Tooltip id="tooltip-hand" />
        <Tooltip id="tooltip-hand-page" />
        <Tooltip id="tooltip-delete" />
        <Tooltip id="tooltip-crop" />
        <Tooltip id="tooltip-full" />
        <Tooltip id="tooltip-mini" />
        <Tooltip id="tooltip-parent-page" />
        <Tooltip id="tooltip-timer" />
        <Tooltip id="tooltip-background" />

        <div className="toolbar">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ width: "100%", position: "relative" }}
          >
            {openMenu && (
              <div id="menu" style={{ display: "block" }} ref={menuRef}>
                <div className="menu-wrapper" style={{ position: "relative" }}>
                  <MenuButton
                    icon={{
                      type: "link",
                      icon: "/icons/ButtonStopwatch.svg",
                    }}
                    text={"Kronometre"}
                    handleClick={() => setTimer(true)}
                  />

                  <MenuButton
                    icon={{
                      type: "link",
                      icon: "/icons/ButtonPresentationMode.svg",
                    }}
                    text={"Arka Plan"}
                    handleClick={() => setSelectBackground(true)}
                  />
                </div>
              </div>
            )}

            <Draggable>
              {timer && (
                <Timer
                  close={() => {
                    setTimer(false);
                  }}
                />
              )}
            </Draggable>

            <div
              className="left d-flex justify-content-between align-items-center"
              style={{ width: "70%" }}
            >
              <div className="left-in-left d-flex align-items-center">
                {/* Menu */}
                <Buttons
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonMenu.svg",
                  }}
                  handleClick={() => {
                    setOpenMenu(!openMenu);
                  }}
                  styleCodes={{
                    width: "90px",
                  }}
                />
                {/* Menu */}

                <Buttons
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonContent.svg",
                  }}
                  text={"İçindekiler"}
                  handleClick={() => {
                    setOpenContents(!openAnswer);
                    if (!openAnswer) {
                      contentsHandler(book.id);
                    }
                  }}
                />
                {/* <Buttons
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonPreview.svg",
                  }}
                  handleClick={() =>
                    Key !== "book" ? setKey("book") : setKey("")
                  }
                  text={"Önizleme"}
                  iconSize={"30px"}
                  styleCodes={{
                    boxShadow: "0px 0px 10px 0px rgb(0 0 0 / 30%)",
                  }}
                /> */}
              </div>
              <div className="left-in-center d-flex align-items-center">
                <Buttons
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonAlignByWidth.svg",
                  }}
                  text={"Genişlet"}
                  disabled={preview}
                  iconSize={30}
                  handleClick={() => {
                    onChangeMode("fullWidth");
                  }}
                />
                <Buttons
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonAlignByHeight.svg",
                  }}
                  disabled={preview}
                  text={"Sığdır"}
                  iconWidth={"40px"}
                  handleClick={() => {
                    onChangeMode("fullHeight");
                  }}
                  bgColor="rgb(255, 150, 65)"
                />
                <Buttons
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonCrop.svg",
                  }}
                  text={"Seçim"}
                  iconWidth={"40px"}
                  handleClick={() => {
                    setCursorStyle("cursor-crosshair");
                    onChangeMode("cute");
                  }}
                />
                <Buttons
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonTarget.svg",
                  }}
                  text={"Son Sayfa"}
                  iconWidth={"30px"}
                  handleClick={() => {
                    const localPageData = JSON.parse(
                      localStorage.getItem(book.id)
                    );

                    handleChangePage(localPageData.currentPage);
                  }}
                />
              </div>
              <div className="left-in-end d-flex justify-content-center">
                {showPageSelect && (
                  <PageNumber
                    pageNumberRef={pageNumberRef}
                    currentNumber={currentNumber}
                    setCurrentNumber={setCurrentNumber}
                    goPageHandle={handlePage}
                    setShowPageSelect={setShowPageSelect}
                  />
                )}

                <LeftMenuButton
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonBack.svg",
                  }}
                  handleClick={() => {
                    handlePrevPage();
                  }}
                />
                <button
                  onClick={() => {
                    setShowPageSelect(!showPageSelect);
                  }}
                  style={{ minWidth: "50px" }}
                >
                  {preview ? (
                    <span>{`${previewCount} / ${
                      questions ? questions.length : 0
                    }`}</span>
                  ) : (
                    <span>{`${page} / ${
                      book ? (book.pages ? book.pages.length : 0) : 0
                    }`}</span>
                  )}
                </button>
                <LeftMenuButton
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonForward.svg",
                  }}
                  handleClick={() => {
                    handleNextPage();
                  }}
                />
              </div>

              {preview && (
                <div>
                  <Buttons
                    icon={{
                      type: "link",
                      icon: "/icons/ButtonAnswer.svg",
                    }}
                    text={"Cevap"}
                    iconSize={30}
                    handleClick={() => {
                      setAnswer(questions[previewCount - 1]);
                      setOpenAnswer(!openAnswer);
                    }}
                    styleCodes={{
                      color: "green",
                    }}
                  />
                </div>
              )}
            </div>
            <div className="right d-flex align-items-center">
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "rgba(76, 76, 76,0.6)",
                  borderRadius: "40px",
                }}
              >
                <Buttons
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonPageMode.svg",
                  }}
                  handleClick={() => {
                    closePreview();
                    setPreviewCount(!preview);
                  }}
                  styleCodes={{
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    marginRight: "-10px",
                    backgroundColor: preview ? "transparent" : "white",
                  }}
                  bgColor={!preview ? "gray" : "white"}
                  disabled={!preview === true ? true : false}
                />

                <Buttons
                  icon={{
                    type: "link",
                    icon: "/icons/ButtonPresentationMode.svg",
                  }}
                  handleClick={() => {
                    setLastQuestionNow();
                    closePreview();
                  }}
                  styleCodes={{
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    backgroundColor: !preview ? "transparent" : "white",
                  }}
                  disabled={preview}
                  bgColor={preview ? "gray" : "white"}
                />
              </div>

              <Buttons
                icon={{
                  type: "svg",
                  icon: (
                    <svg
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      width={18}
                      height={18}
                    >
                      <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"></path>
                    </svg>
                  ),
                }}
                handleClick={() => {
                  handleFullScreen();
                }}
                styleCodes={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                }}
              />
            </div>
          </div>
        </div>

        {/* Önizleme Popoverı */}
        {book.pages && book.pages.length > 0 && (
          <div className={`preview popup ${Key === "book" && "active"}`}>
            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "0px",
                transform: "translate(-50%, -50%)",
                zIndex: 999999999,
              }}
            >
              <LeftMenuButton
                icon={{
                  type: "link",
                  icon: "/icons/ButtonBack.svg",
                }}
                handleClick={() => {
                  document.getElementById("img-container").scrollLeft -= 200;
                }}
                styleCodes={{
                  boxShadow: "rgba(0, 0, 0, 0.43) 0px 0px 10px 0px",
                }}
              />
            </span>
            <span
              style={{
                position: "absolute",
                top: "50%",
                right: "-80px",
                transform: "translate(-50%,-50%)",
                zIndex: 999999999,
              }}
            >
              <LeftMenuButton
                icon={{
                  type: "link",
                  icon: "/icons/ButtonForward.svg",
                }}
                handleClick={() => {
                  document.getElementById("img-container").scrollLeft += 200;
                }}
                styleCodes={{
                  boxShadow: "rgba(0, 0, 0, 0.43) 0px 0px 10px 0px",
                }}
              />
            </span>
            <div className="img-container" id="img-container">
              {book?.pages.map((item, idx) => {
                return (
                  <div
                    onClick={() => {
                      handleChangePage(idx + 1);
                      setKey("");
                    }}
                    key={`book-${Math.round(Math.random() * 99999999)}`}
                    className={"img-item"}
                  >
                    <span className="page-number" id={`book-${item.id}`}>
                      {idx + 1}. Sayfa
                    </span>
                    <img src={item.file} alt="" />
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* Arka Plan Modalı */}
        <Modal
          isOpen={selectBackground}
          contentLabel="Arkaplan Seçiniz"
          className={"Modall"}
          overlayClassName="Overlayy"
        >
          <div className={"bg-title"}>
            <h2>Arkaplan Seçiniz</h2>
          </div>
          <div className="bg-body">
            <ul className="bg-ul">
              {bgs.map((item, index) => {
                return (
                  <li key={index}>
                    <div
                      id={item}
                      style={{
                        borderColor: active === index ? "black" : "white",
                      }}
                    >
                      <img
                        onClick={() => {
                          setActive(index);
                        }}
                        src={item}
                        alt=""
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={"bg-bottom"}>
            <button
              className={"bg-save"}
              onClick={() => {
                setSelectBackground(false);
                setActive(999);
              }}
            >
              Vazgeç
            </button>
            <button
              className={"bg-save"}
              onClick={() => {
                save();
              }}
            >
              Kaydet
            </button>
          </div>
        </Modal>

        {/* İçindekiler Modalı */}

        <Modal
          isOpen={openContents}
          className={"contentModal"}
          overlayClassName="Overlayy"
        >
          <div
            className="header d-flex justify-content-between align-items-center"
            style={{ padding: "8px 16px" }}
          >
            <h3 className="flex-1">Başlık</h3>
            <span className="flex-1" onClick={() => setOpenContents(false)}>
              <svg
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                fill="black"
                stroke="black"
                width={20}
                height={20}
              >
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              </svg>
            </span>
          </div>
          <div className="inside-list">
            <div
              className="d-flex justify-content-between align-items-center py-2 px-3"
              style={{ borderBottom: "4px solid #e6e6e6df" }}
            >
              <span style={{ width: "70%" }}>Başlık</span>
              <span style={{ width: "30%", textAlign: "center" }}>Sayfa</span>
            </div>
            {contentList.map((item, index) => {
              return (
                <div
                  className="modal-content-list d-flex justify-content-between align-items-center py-2 px-3"
                  key={index}
                >
                  <span style={{ width: "70%" }}>{item.name}</span>
                  <span
                    style={{
                      width: "30%",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setPage(item.page);
                    }}
                  >
                    {item.page}
                  </span>
                </div>
              );
            })}
          </div>
        </Modal>
      </div>

      {/*  Ana Sayfa Butonu */}

      <Buttons
        icon={{
          type: "svg",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          ),
        }}
        handleClick={() => navigate("/")}
        text={"Ana Sayfa"}
        iconSize={"40px"}
        styleCodes={{
          boxShadow: "0px 0px 10px 0px rgb(0 0 0 / 30%)",
          position: "absolute",
          left: "15px",
          top: "15px",
          zIndex: "999999",
          cursor: "pointer",
          width: "64px",
          border: "none",
        }}
      />
    </>
  );
};

export default Menu;
