import axios from 'axios'

import { pdfjs } from 'react-pdf'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

import IndexDatabase from './IndexDatabase'

const PdfToBase64 = async (url, id) => {
    try {
        const file = await axios.get(url, { responseType: 'blob' }).then(r => (r.data))

        const reader = new FileReader()
        reader.onloadend = async () => {
            const pdf = await pdfjs.getDocument({ data: reader.result }).promise
            const numPages = 5
            for (let i = 1; i <= numPages; i++) {
                const page = await pdf.getPage(i)
                const viewport = page.getViewport({ scale: 5.0 })
                const canvas = document.createElement('canvas')
                const context = canvas.getContext('2d')
                canvas.height = viewport.height
                canvas.width = viewport.width
                await page.render({ canvasContext: context, viewport: viewport }).promise;
                const base64String = canvas.toDataURL()
                await IndexDatabase(`book-${id}`, 'pages', 'create', base64String, `page-${i}`)
                console.log(`${i}. Sayfa Eklendi`)
            }
        }


        reader.readAsArrayBuffer(file)
    } catch (error) {
        console.log(error)
    }
}

export default PdfToBase64