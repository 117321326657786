import React, { useState } from "react";

export default function PageNumber({
  currentNumber,
  setCurrentNumber,
  goPageHandle,
  setShowPageSelect,
  pageNumberRef,
}) {
  const saveHandler = () => {
    goPageHandle();
  };
  const [numbers, setNumbers] = useState([
    {
      id: 1,
      title: 1,
      handle: () => {
        setCurrentNumber((old) => old + "1");
      },
    },
    {
      id: 2,
      title: 2,
      handle: () => {
        setCurrentNumber((old) => old + "2");
      },
    },
    {
      id: 3,
      title: 3,
      handle: () => {
        setCurrentNumber((old) => old + "3");
      },
    },
    {
      id: 4,
      title: 4,
      handle: () => {
        setCurrentNumber((old) => old + "4");
      },
    },
    {
      id: 5,
      title: 5,
      handle: () => {
        setCurrentNumber((old) => old + "5");
      },
    },
    {
      id: 6,
      title: 6,
      handle: () => {
        setCurrentNumber((old) => old + "6");
      },
    },
    {
      id: 7,
      title: 7,
      handle: () => {
        setCurrentNumber((old) => old + "7");
      },
    },
    {
      id: 8,
      title: 8,
      handle: () => {
        setCurrentNumber((old) => old + "8");
      },
    },
    {
      id: 9,
      title: 9,
      handle: () => {
        setCurrentNumber((old) => old + "9");
      },
    },
  ]);

  return (
    <div className="page-number-wrapper" ref={pageNumberRef}>
      <div className="page-number-input-wrapper">
        <input
          type="number"
          placeholder="Sayfa Numarası"
          readOnly
          value={currentNumber}
        />
        <div className="numbers">
          {numbers.map((n, i) => {
            return (
              <button key={i} onClick={() => n.handle()} className="n-button">
                {n.icon ? n.icon : n.title}
              </button>
            );
          })}
          <button
            onClick={() => {
              const current = currentNumber.split("");
              current.pop();
              console.log(current);
              setCurrentNumber(current.join(","));
            }}
            className="n-button"
          >
            <svg
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              style={{
                fill: "currentColor",
                width: "1em",
                height: "1em",
                display: "inline-block",
                fontSize: " 1.5rem",
                transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                flexShrink: 0,
                userSelect: "none",
              }}
            >
              <path d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H7.07L2.4 12l4.66-7H22v14zm-11.59-2L14 13.41 17.59 17 19 15.59 15.41 12 19 8.41 17.59 7 14 10.59 10.41 7 9 8.41 12.59 12 9 15.59z"></path>
            </svg>
          </button>
          <button
            onClick={() => {
              setCurrentNumber((old) => old + "0");
            }}
            className="n-button"
          >
            0
          </button>
          <button
            onClick={() => {
              console.log(currentNumber);
              saveHandler();
              setCurrentNumber("");
              setShowPageSelect(false);
            }}
            className="n-button"
          >
            <svg
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              style={{
                fill: "currentColor",
                width: "1em",
                height: "1em",
                display: "inline-block",
                fontSize: " 1.5rem",
                transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                flexShrink: 0,
                userSelect: "none",
              }}
            >
              <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
