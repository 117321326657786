import React, { Component } from "react";
import PropTypes from "prop-types";
import { fabric } from "fabric";
import "../Helpers/Eraser";
import convertImageToBase64 from "../Helpers/imageToBase64";

fabric.Object.prototype.selectable = false;

export default class DrawPanel extends Component {
  static propTypes = {
    brushRadius: PropTypes.number,
    cursorStyle: PropTypes.string,
    brushColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    disabled: PropTypes.bool,
    imgSrc: PropTypes.string,
    activeMode: PropTypes.string,
    isTreshMode: PropTypes.bool,
    trashRadius: PropTypes.number,
    zoomMarkers: PropTypes.array,
    zoomPresenter: PropTypes.object,
    onZoomMarker: PropTypes.func,
    setPreviewCount: PropTypes.func,
    newPreviewCoords: PropTypes.object,
    preview: PropTypes.bool,
    questions: PropTypes.array,
    currentPage: PropTypes.number,
  };

  static defaultProps = {
    brushRadius: 10,
    brushColor: "#000",
    backgroundColor: "#FFF",
    disabled: false,
    imgSrc: null,
    isTreshMode: false,
    activeMode: "paint",
    trashRadius: 10,
    zoomMarkers: null,
    zoomPresenter: null,
    setPreviewCount: () => {},
    onZoomMarker: () => {},
    newPreviewCoords: {},
    preview: false,
  };

  constructor(props) {
    super(props);
    this.canvasRef = {};
    this.cursorRef = React.createRef();
    this.canvas = null;
    this.isMode = "paint";
    this.isMove = false;

    this.lastPosX = 0;
    this.lastPosY = 0;

    this.markerImage = "/zoomIn.png";
    this.isRenderMarker = false;

    this.createdLine = null;
    this.pageStatus = null;

    this.createdCuteRect = null;

    this.renderedImage = null;

    this.previewCount = 0;
    this.currentPreview = 0;
  }

  showMarker = (coodinates) => {
    return new Promise((resolve, reject) => {
      // TODO : BURASI!
      var img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = function () {
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(
          img,
          coodinates.x,
          coodinates.y,
          coodinates.w,
          coodinates.h,
          0,
          0,
          coodinates.w * 2,
          coodinates.h * 2
        );
        const imageNewUrl = canvas.toDataURL();
        resolve(imageNewUrl);
      };
      img.onerror = function () {
        reject(new Error("Resim yüklenirken bir hata oluştu."));
      };

      let sourceUrl = this.props.imgSrc;
      if (sourceUrl.startsWith("http")) {
        sourceUrl = "https://service.akillitahta.net/proxy?url=" + sourceUrl;
      }
      img.src = sourceUrl;
    });
  };

  showPreview = async (index) => {
    if (index > -1 && index <= this.props.zoomMarkers?.length) {
      const findIndex = this.props.zoomMarkers.findIndex((x) => x.oi === index);
      const currentTagert = this.props.zoomMarkers[findIndex];
      this.currentPreview = index;
      const croppedData = await this.showMarker(currentTagert);
      return croppedData;
    }

    return null;
  };

  renderCanvas = async () => {
    if (this.canvas === null) {
      this.canvas = new fabric.Canvas(this.canvasRef, {
        width: window.innerWidth,
        height: window.innerHeight,
        selection: false,
        isDrawingMode: true,
        isTouchSupported: true,
        backgroundColor: "transparent",
      });

      this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
      this.canvas.freeDrawingBrush.width = this.props.brushRadius;
      this.canvas.freeDrawingBrush.color = this.props.brushColor;

      this.canvas.on("object:selected", function (e) {
        this.canvas.discardActiveObject();
      });

      this.canvas.on("path:created", function (opt) {
        if (!opt.path.dirty) {
          opt.selected = false;
        }
      });
    }

    let sourceUrl = this.props.imgSrc;
    if (sourceUrl?.startsWith("http")) {
      sourceUrl = await convertImageToBase64(
        "https://service.akillitahta.net/proxy?url=" + sourceUrl
      );
    }

    fabric.Image.fromURL(sourceUrl, (img) => {
      const imgLeft = this.canvas.width / 2 - img.width / 2;
      const imgRight = this.canvas.height / 2 - img.height / 2;
      img.set({
        left: this.props.preview === true ? 200 : imgLeft,
        top: this.props.preview === true ? 50 : imgRight,
        selectable: false,
        erasable: false,
      });

      img.set({ erasable: false });

      this.renderedImage = img;
      this.canvas.add(img);
      if (this.props.preview !== true) {
        this.canvas.zoomToPoint(
          { x: this.canvas.width / 2, y: this.canvas.height / 2 },
          0.5
        );
        this.markerCrete(imgLeft, imgRight);
      } else {
        this.canvas.zoomToPoint({ x: 0, y: 0 }, 0.5);
      }

      this.canvas.renderAll();
    });

    const selfArea = this;
    let initialPinchDistance = 0;
    let initialZoom = 1;

    this.canvas.on("mouse:down", function (event) {
      if (selfArea.isMode == "move") {
        if (event.e.type === "touchstart") {
          if (event.e.touches.length === 2) {
            selfArea.isMove == false;
            initialPinchDistance = Math.hypot(
              event.e.touches[1].pageX - event.e.touches[0].pageX,
              event.e.touches[1].pageY - event.e.touches[0].pageY
            );
            initialZoom = selfArea.canvas.getZoom();
          }

          selfArea.lastPosX = event.e.changedTouches[0].clientX;
          selfArea.lastPosY = event.e.changedTouches[0].clientY;
          selfArea.isMove = true;
        } else {
          selfArea.lastPosX = event.e.clientX;
          selfArea.lastPosY = event.e.clientY;
          selfArea.isMove = true;
        }
      } else if (selfArea.isMode == "line") {
        var pointer = selfArea.canvas.getPointer(event.e);
        var points = [pointer.x, pointer.y, pointer.x, pointer.y];
        selfArea.createdLine = new fabric.Line(points, {
          stroke: "black",
          strokeWidth: 10,
          hasControls: false,
          hasBorders: true,
          lockMovementX: false,
          lockMovementY: false,
          // hoverCursor: 'col-resize',
          // moveCursor: "col-resize",
          selectable: false,
          strokeLineCap: "round",
          strokeLineJoin: "round",
          strokeDashArray: [5, 5],
        });
        selfArea.canvas.add(selfArea.createdLine);
      } else if (selfArea.isMode == "cute") {
        // selectedObject.set("cursor", "row-resize !important")
        // selectedObject.canvas.upperCanvasEl.style.cursor = "row-resize"
        var pointer = selfArea.canvas.getPointer(event.e);
        selfArea.createdCuteRect = new fabric.Rect({
          left: pointer.x,
          top: pointer.y,
          width: 0,
          height: 0,
          fill: "transparent",
          stroke: "red",
          strokeWidth: 2,
          selectable: false,
        });
        selfArea.canvas.add(selfArea.createdCuteRect);
      }
    });

    this.canvas.on("mouse:wheel", (event) => {
      const delta = event.e.deltaY;
      let zoom = this.canvas.getZoom();

      zoom *= 0.999 ** delta;

      if (zoom > 10) zoom = 10;
      if (zoom < 0.1) zoom = 0.1;

      this.canvas.zoomToPoint({ x: event.pointer.x, y: event.pointer.y }, zoom);

      event.e.preventDefault();
      event.e.stopPropagation();
    });

    this.canvas.on("mouse:move", function (event) {
      if (selfArea.isMode == "move" && selfArea.isMove == true) {
        // * Sorun Burada
        if (event.e.type === "touchmove") {
          if (event.e.touches.length === 2) {
            selfArea.isMove == false;

            initialPinchDistance = Math.hypot(
              event.e.touches[1].pageX - event.e.touches[0].pageX,
              event.e.touches[1].pageY - event.e.touches[0].pageY
            );

            // * Sorun Tam Olarak Burada
            // * Buradaki sorun iki noktaya tıklandığında bir önceki değere baz alınıyor bunun o anki değeri baz alınmalı

            selfArea.canvas.zoomToPoint(
              { x: event.pointer.x, y: event.pointer.y },
              initialPinchDistance / 800
            );

            // console.log("event.e", event.e);

            event.e.preventDefault();
            event.e.stopPropagation();

            // initialZoom = selfArea.canvas.getZoom();
          } else {
            const currentPosX = event.e.changedTouches[0].clientX;
            const currentPosY = event.e.changedTouches[0].clientY;
            const deltaX = currentPosX - selfArea.lastPosX;
            const deltaY = currentPosY - selfArea.lastPosY;
            selfArea.canvas.relativePan({ x: deltaX, y: deltaY });
            selfArea.lastPosX = currentPosX;
            selfArea.lastPosY = currentPosY;
          }
        } else {
          const currentPosX = event.e.clientX;
          const currentPosY = event.e.clientY;
          const deltaX = currentPosX - selfArea.lastPosX;
          const deltaY = currentPosY - selfArea.lastPosY;
          selfArea.canvas.relativePan({ x: deltaX, y: deltaY });
          selfArea.lastPosX = currentPosX;
          selfArea.lastPosY = currentPosY;
        }
      } else if (selfArea.isMode == "line" && selfArea.createdLine != null) {
        var pointer = selfArea.canvas.getPointer(event.e);
        selfArea.createdLine.set({
          x2: pointer.x,
          y2: pointer.y,
        });
        selfArea.canvas.renderAll();
      } else if (
        selfArea.isMode == "cute" &&
        selfArea.createdCuteRect != null
      ) {
        var pointer = selfArea.canvas.getPointer(event.e);
        selfArea.createdCuteRect.set({
          width: pointer.x - selfArea.createdCuteRect.left,
          height: pointer.y - selfArea.createdCuteRect.top,
        });
        selfArea.canvas.renderAll();
      } else if (selfArea.isMode == "trash") {
        const cursorPosition = selfArea.canvas.getPointer();
        selfArea.canvas.getObjects("circle").forEach((i) => {
          selfArea.canvas.remove(i);
        });
        const circle = new fabric.Circle({
          radius: selfArea.props.trashRadius / 2,
          fill: "rgba(255, 255, 255, 0.6)",
          stroke: "black",
          strokeWidth: 4,
        });

        circle.set({
          left: cursorPosition.x - selfArea.props.trashRadius / 2,
          top: cursorPosition.y - selfArea.props.trashRadius / 2,
        });
        selfArea.canvas.add(circle);
      }
    });

    this.canvas.on("mouse:up", function (event) {
      if (selfArea.isMode == "move" && selfArea.isMove == true) {
        selfArea.isMove = false;
      } else if (selfArea.isMode == "line" && selfArea.createdLine != null) {
        selfArea.createdLine.setCoords();
        selfArea.createdLine = null;
      } else if (
        selfArea.isMode == "cute" &&
        selfArea.createdCuteRect != null
      ) {
        selfArea.createdCuteRect.setCoords();

        const coordinants = {
          left: selfArea.createdCuteRect.left - selfArea.renderedImage.left,
          top: selfArea.createdCuteRect.top - selfArea.renderedImage.top,
          width: selfArea.createdCuteRect.width,
          height: selfArea.createdCuteRect.height,
        };

        //TODO: UĞURCAN BURASI CROP İŞLEMİ YAPILDIKTAN SONRA coordinants BURAYA KONUMLARINI VERİYOR BUNLARI ALABİLİRSİN.

        var croppedImageBase64 = selfArea.renderedImage.toDataURL({
          left: coordinants.left,
          top: coordinants.top,
          width: coordinants.width,
          height: coordinants.height,
        });

        selfArea.props.onZoomMarker(croppedImageBase64);
        selfArea.canvas.remove(selfArea.createdCuteRect);
        selfArea.createdCuteRect = null;
        selfArea.canvas.renderAll();
      }
    });

    this.canvas.on("mouse:wheel", (event) => {
      const delta = event.e.deltaY;
      let zoom = this.canvas.getZoom();
      zoom *= 0.999 ** delta;

      if (zoom > 10) zoom = 10;
      if (zoom < 0.1) zoom = 0.1;

      this.canvas.zoomToPoint({ x: event.pointer.x, y: event.pointer.y }, zoom);
      event.e.preventDefault();
      event.e.stopPropagation();
    });

    // * Touch Events
  };

  markerCrete = (left, right) => {
    if (this.isRenderMarker) return;
    this.previewCount = this.props.zoomMarkers?.length;
    this.props.zoomMarkers?.forEach((marker, index) => {
      fabric.Image.fromURL(this.markerImage, (img) => {
        const imgLeft = left + marker.x;
        const imgRight = right + marker.y;
        img.set({
          left: imgLeft - 10,
          top: imgRight - 10,
          selectable: false,
          erasable: false,
          id: marker.oi,
        });
        img.set({ erasable: false });
        img.on("mousedown", async (e) => {
          this.props.setPreviewCount(marker.oi);
          this.currentPreview = index;
          if (e.target) {
            let id = parseInt(e.target.id);
            const ind = this.props.zoomMarkers.findIndex((x) => x.oi === id);
            const currentTagert = this.props.zoomMarkers[ind];
            this.currentPreview = e.target.id;
            const croppedData = await this.showMarker(currentTagert);
            this.props.onZoomMarker(croppedData);
          }
        });
        this.canvas.add(img);
        this.canvas.zoomToPoint(
          { x: this.canvas.width / 2, y: this.canvas.height / 2 },
          0.5
        );
        this.markerCrete(imgLeft, imgRight);
        this.canvas.renderAll();
      });
    });
    if (this.previewCount > 0) this.isRenderMarker = true;
  };

  componentDidMount() {
    this.renderCanvas();
  }

  componentDidUpdate() {
    this.renderCanvas();
  }

  shouldComponentUpdate(nextProps, nextState, prevProps) {
    const currentCursor = nextProps.cursorStyle;
    // TODO: Silgi muhabbetine patlatıyor.
    //this.canvas.freeDrawingBrush = this.props.cursorStyle;
    this.canvas.upperCanvasEl.classList.value = "";

    this.canvas.upperCanvasEl.classList.add("upper-canvas");
    this.canvas.upperCanvasEl.classList.add(currentCursor);
    if (!this.props.isActive) return false;

    const oldMode = this.isMode?.toString();

    this.isMode = nextProps.activeMode.type;

    if (this.isMode === "paint2") {
      this.canvas.isDrawingMode = true;
      this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
      this.canvas.freeDrawingBrush.width = nextProps.brushRadius;
      this.canvas.freeDrawingBrush.color = nextProps.brushColor;
    }
    if (this.isMode === "paint") {
      this.canvas.isDrawingMode = true;
      this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
      this.canvas.freeDrawingBrush.width = nextProps.brushRadius;

      var hex = nextProps.brushColor.replace("#", "");

      var r = parseInt(hex.substring(0, 2), 16);
      var g = parseInt(hex.substring(2, 4), 16);
      var b = parseInt(hex.substring(4, 6), 16);

      var brushColor = "rgba(" + r + ", " + g + ", " + b + ", " + 0.5 + ")";

      this.canvas.freeDrawingBrush.color = brushColor;
      this.canvas.upperCanvasEl.hoverCursor = "no-drop";
    }
    if (this.isMode === "drag") {
      this.canvas.isDrawingMode = false;
    }
    if (this.isMode === "trash") {
      const eraserBrush = new fabric.EraserBrush(this.canvas);
      eraserBrush.width = this.props.trashRadius;
      eraserBrush.color = "#ffffff";
      this.canvas.freeDrawingBrush = eraserBrush;
      this.canvas.isDrawingMode = true;
    }
    if (this.isMode === "rect" && oldMode !== "rect") {
      this.canvas.isDrawingMode = false;
      const square = new fabric.Rect({
        left: this.canvas.width / 2 - 100,
        top: this.canvas.height / 2 - 100,
        width: 200,
        height: 200,
        stroke: this.props.brushColor,
        strokeWidth: 10,
        fill: "",
        selectable: true,
      });

      this.canvas.add(square);
    }
    if (this.isMode === "triangle" && oldMode !== "triangle") {
      this.canvas.isDrawingMode = false;
      const square = new fabric.Triangle({
        left: this.canvas.width / 2 - 100,
        top: this.canvas.height / 2 - 100,
        width: 200,
        height: 200,
        stroke: this.props.brushColor,
        strokeWidth: 10,
        fill: "",
        selectable: true,
      });

      this.canvas.add(square);
    }
    if (this.isMode === "circle" && oldMode !== "circle") {
      this.canvas.isDrawingMode = false;
      const square = new fabric.Circle({
        left: this.canvas.width / 2 - 100,
        top: this.canvas.height / 2 - 100,
        radius: 200,
        stroke: this.props.brushColor,
        strokeWidth: 10,
        fill: "",
        selectable: true,
      });

      this.canvas.add(square);
    }
    if (this.isMode === "line" && oldMode !== "line") {
      this.canvas.isDrawingMode = false;
    }
    if (this.isMode === "clear" && oldMode !== "clear") {
      this.canvas.isDrawingMode = false;
      this.canvas.clear();
      this.isRenderMarker = false;
      const positions = this.canvas.viewportTransform;
      this.renderCanvas();
      setTimeout(() => {
        this.canvas.setViewportTransform(positions);
      }, 10);
    }
    if (this.isMode === "move") {
      this.canvas.isDrawingMode = false;
    }
    if (this.isMode === "fullWidth") {
      this.pageStatus = nextProps.pageStatus;
      var zoomLevel = 1.2;
      var objectLeft = window.innerWidth / 2;
      var objectTop = window.innerHeight / 9;

      if (window.innerWidth <= 1920) {
        zoomLevel = 0.45;
        objectLeft = window.innerWidth / 2;
        objectTop = window.innerHeight / 30;
      }

      var newLeft = -objectLeft * zoomLevel + this.canvas.width / 2;
      var newTop = -objectTop * zoomLevel + this.canvas.height / 1.3;

      this.canvas.setViewportTransform([
        zoomLevel,
        0,
        0,
        zoomLevel,
        newLeft,
        newTop,
      ]);
    }
    if (this.isMode === "fullHeight") {
      this.pageStatus = nextProps.pageStatus;
      var zoomLevel = 0.2;

      var objectLeft = window.innerWidth / 2;
      var objectTop = window.innerHeight / 1.8;

      if (window.innerWidth <= 1920) {
        zoomLevel = 0.2;
        objectLeft = window.innerWidth / 2;
        objectTop = window.innerHeight / 1.7;
      }

      var newLeft = -objectLeft * zoomLevel + this.canvas.width / 2;
      var newTop = -objectTop * zoomLevel + this.canvas.height / 2;

      this.canvas.setViewportTransform([
        zoomLevel,
        0,
        0,
        zoomLevel,
        newLeft,
        newTop,
      ]);
    }
    if (this.isMode == "cute" && oldMode !== "cute") {
      this.canvas.isDrawingMode = false;
    }

    if (this.props.imgSrc != nextProps.imgSrc) {
      if (this.preview) {
        this.props.imgSrc = nextProps.imgSrc;
      }
      this.canvas.clear();
      setTimeout(() => {
        this.renderCanvas();
      }, 100);
    }

    // this.canvas.freeDrawingCursor = this.isMode === "trash" ? `url("/cursor-images/trash-cursor.png") ${this.brushRadius} ${this.brushRadius}` : this.isMode === "paint2" ? "url(`https://cdn-icons-png.flaticon.com/512/3074/3074740.png`)" : this.isMode === "paint" ? "url(`/icons/ButtonMarker.svg`) 15 15" : "pointer"

    return false;
  }

  render() {
    return (
      <div>
        <canvas ref={(ref) => (this.canvasRef = ref)} id="canvas" />
      </div>
    );
  }
}
