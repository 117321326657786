import {useEffect, useState} from 'react'
import indexDatabase from '../Helpers/IndexDatabase'

import DownloadImage from '../Assets/Images/download.png'
import VerifyImage from '../Assets/Images/check.png'
import ExportImage from '../Assets/Images/share.png'
import DeleteImage from '../Assets/Images/delete.png'

const BookOne = ({book, download, build, remove, load, navigate, upload, add}) => {

    const [isDownload, setIsDownload] = useState(false)

    const checkLocalStorage = async id => {
        const check = await localStorage.getItem(`book-${id}`)
        setIsDownload(check ? true : false)
    }

    useEffect(() => {
        checkLocalStorage(book.id)
    }, [load])

    return (
        <div className="book-one">
            {
                upload ?
                    <div className='upload-one' onClick={() => { add() }}>
                        Kitap Yükle
                    </div>
                    :
                    <>
                        <img onClick={() => { navigate() }} src={book.image} alt={book.name}/>
                        <div className="book-icons">
                            <div className="book-icons-set">
                                {
                                    isDownload &&
                                    <button className="book-downloaded">
                                        <img src={VerifyImage} alt=""/>
                                    </button>
                                }
                                <button className="book-download" onClick={() => { download() }}>
                                    <img src={DownloadImage} alt=""/>
                                </button>
                                {
                                    1 !== 1 &&
                                    <button className="book-remove" onClick={() => { build() }}>
                                        <img src={ExportImage} alt=""/>
                                    </button>
                                }
                            </div>
                            {
                                isDownload &&
                                <button className="book-remove" onClick={() => { remove() }}>
                                    <img src={DeleteImage} alt=""/>
                                </button>
                            }
                        </div>
                    </>
            }
        </div>
    )
}

export default BookOne