import { useRef } from "react";
import LeftMenuButton from "../LeftMenuButton";
import React, { useState } from "react";
import { useEffect } from "react";
import Timer from "../Timer";
import { Draggable } from "drag-react";
import Buttons from "../Buttons";
export default function RightToolbar({
  preview,
  brushColorChange = (color) => {},
  brushSizeChange = (size) => {},
  brushRadius,
  onChangeMode = (mode) => {},
  closePreview = () => {},
  setLastQuestionNow = () => {},
  setPreviewCount = (index) => {},
  trashSizeChange,
  trashRadius,
  openAnswer,
  setOpenAnswer,
  questions = [],
  previewCount,
  page,
  book,
  FullScreen,
  setFullScreen,
}) {
  const [Key, setKey] = useState("");
  const [Color, setColor] = useState("#000");
  const [showColorPalette, setShowColorPalette] = useState(false);
  const [openTrashWidth, setOpenTrashWidth] = useState(false);
  const [showShapes, setShowShapes] = useState(false);
  const [timer, setTimer] = useState(false);
  const colors = [
    "#ff0000",
    "#00ff00",
    "#0000ff",
    "#ffff00",
    "#ff00ff",
    "#00ffff",
    "#000000",
    "#ffffff",
  ];
  const colorPaletteRef = useRef();
  const eraserPaletteRef = useRef();
  const menuRef = useRef();
  const shapeRef = useRef();

  const handleClickOutside = (event) => {
    const popups = document.querySelectorAll(".popup");
    const clickedInsidePopup = Array.from(popups).some((popup) =>
      popup.contains(event.target)
    );
    if (!clickedInsidePopup) {
      setKey("");
    }
    if (
      colorPaletteRef.current &&
      !colorPaletteRef.current.contains(event.target)
    ) {
      setShowColorPalette(false);
    }
    if (
      eraserPaletteRef.current &&
      !eraserPaletteRef.current.contains(event.target)
    ) {
      setOpenTrashWidth(false);
    }
    if (shapeRef.current && !shapeRef.current.contains(event.target)) {
      setShowShapes(false);
    }
  };
  const handleFullScreen = () => {
    let element = document.documentElement;
    if (!FullScreen) {
      setFullScreen(true);
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }

    if (FullScreen) {
      setFullScreen(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      className="right-toolbar"
      style={{
        position: "fixed",
        width: "5rem",
        right: 0,
        top: 0,
        backgroundColor: "white",
        height: "100vh",
        color: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "15px 0px",
        zIndex: 9999999999,
        color: "black",
        boxShadow: "rgba(0, 0, 0, 0.3) 1px 0px 10px 0px",
      }}
    >
      <div style={{ position: "relative" }}>
        <LeftMenuButton
          styleCodes={{ backgroundColor: "rgb(251, 251, 251)" }}
          icon={{
            type: "link",
            icon: "/icons/ButtonHand.svg",
          }}
          handleClick={() => onChangeMode("move")}
          iconWidth="44px"
          iconHeight="44px"
        />

        <LeftMenuButton
          styleCodes={{ backgroundColor: "rgb(251, 251, 251)" }}
          icon={{
            type: "link",
            icon: "/icons/ButtonPen.svg",
          }}
          handleClick={() => {
            onChangeMode("paint2");
            setKey("pen2");
            setShowColorPalette(!showColorPalette);
          }}
          iconWidth="44px"
          iconHeight="44px"
        />
        <LeftMenuButton
          styleCodes={{ backgroundColor: "rgb(251, 251, 251)" }}
          icon={{
            type: "link",
            icon: "/icons/ButtonMarker.svg",
          }}
          handleClick={() => {
            onChangeMode("paint");
            setKey("pen");
            setShowColorPalette((prev) => !prev);
          }}
          iconWidth="44px"
          iconHeight="44px"
        />
        {showColorPalette && (
          <div className={"r-color-palette"} ref={colorPaletteRef}>
            {colors.map((color, index) => (
              <div
                key={`color-palette-${index}`}
                style={{
                  backgroundColor: color,
                  width: "18px",
                  height: "18px",
                  borderRadius: "18px",
                  display: "inline-block",
                  margin: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  brushColorChange(color);
                  setColor(color);
                }}
              />
            ))}
            <span
              className="brush-line popup"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="100px"
                height="10.000000pt"
                viewBox="0 0 94.000000 10.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,10.000000) scale(0.100000,-0.100000)"
                  fill={Color}
                  stroke={Color}
                  strokeWidth={brushRadius}
                >
                  <path
                    d="M535 80 c-27 -5 -70 -17 -95 -26 -65 -23 -264 -30 -360 -11 -71 14
 -91 14 -78 1 3 -3 46 -12 95 -21 114 -20 247 -12 372 22 129 34 307 34 444 0
 15 -4 27 -3 27 3 0 27 -301 51 -405 32z"
                  ></path>
                </g>
              </svg>
            </span>
            <input
              type="range"
              min="1"
              max="41"
              defaultValue="10"
              value={brushRadius}
              step={10}
              id="brush-line"
              className="range range-000000"
              style={{ width: "100%", backgroundColor: Color }}
              onInput={(e) => {
                brushSizeChange(parseInt(e.target.value));
              }}
            ></input>
          </div>
        )}
        <LeftMenuButton
          styleCodes={{ backgroundColor: "rgb(251, 251, 251)" }}
          icon={{
            type: "link",
            icon: "/icons/ButtonEraser.svg",
          }}
          handleClick={() => {
            onChangeMode("trash");
            setKey("trash");
            setOpenTrashWidth(!openTrashWidth);
            const canvas = document.querySelectorAll(".upper-canvas");

            for (let i = 0; i < canvas.length; i++) {
              canvas[
                i
              ].style.cursor = `url("data:image/svg+xml;base64,CgkJPHN2ZwoJCQloZWlnaHQ9IjUwIgoJCQlmaWxsPSIjMDAwMDAwMTAiCgkJCXN0cm9rZT0iYmxhY2siCgkJCXN0cm9rZS13aWR0aD0iNSIKCQkJdmlld0JveD0iMCAwIDEwNSAxMDUiCgkJCXdpZHRoPSI1MCIKCQkJeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgoJCT4KCQkJPGNpcmNsZQoJCQkJY3g9IjUwJSIKCQkJCWN5PSI1MCUiCgkJCQlyPSI1MCIgCgkJCS8+CgkJPC9zdmc+Cgk=") 100 100`;
            }
          }}
          iconWidth="44px"
          iconHeight="44px"
        />
        {openTrashWidth && (
          <label
            className={`r-eraser-palette popup ${Key === "trash" && "active"}`}
            ref={eraserPaletteRef}
          >
            <span className="trash-width">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="100px"
                height="10.000000pt"
                viewBox="0 0 94.000000 10.000000"
                preserveAspectRatio="xMidYMid meet"
                stroke="#000"
              >
                <g
                  transform="translate(0.000000,10.000000) scale(0.100000,-0.100000)"
                  strokeWidth={trashRadius}
                >
                  <path
                    d="M535 80 c-27 -5 -70 -17 -95 -26 -65 -23 -264 -30 -360 -11 -71 14
 -91 14 -78 1 3 -3 46 -12 95 -21 114 -20 247 -12 372 22 129 34 307 34 444 0
 15 -4 27 -3 27 3 0 27 -301 51 -405 32z"
                  ></path>
                </g>
              </svg>
            </span>
            <input
              type="range"
              min="1"
              max="100"
              defaultValue="10"
              value={trashRadius}
              style={{ width: "100%", backgroundColor: Color }}
              onInput={(e) => {
                trashSizeChange(parseInt(e.target.value));
              }}
            ></input>
          </label>
        )}
        <LeftMenuButton
          styleCodes={{ backgroundColor: "rgb(251, 251, 251)" }}
          icon={{
            type: "link",
            icon: "/icons/ButtonShapes.svg",
          }}
          handleClick={() => {
            setShowShapes(!showShapes);
          }}
          iconWidth="44px"
          iconHeight="44px"
        />
        {showShapes && (
          <div
            className={
              "r-shapes-palette d-flex justify-content-between align-items-center flex-wrap"
            }
            ref={shapeRef}
          >
            <LeftMenuButton
              icon={{
                type: "link",
                icon: "/icons/line.svg",
              }}
              handleClick={() => onChangeMode("line")}
            />
            <LeftMenuButton
              icon={{
                type: "link",
                icon: "/icons/rectangle.svg",
              }}
              handleClick={() => onChangeMode("rect")}
            />
            <LeftMenuButton
              icon={{
                type: "link",
                icon: "/icons/circle.svg",
              }}
              handleClick={() => onChangeMode("circle")}
            />
            <LeftMenuButton
              icon={{
                type: "link",
                icon: "/icons/triangle.svg",
              }}
              handleClick={() => onChangeMode("triangle")}
            />
          </div>
        )}
        <div style={{ position: "relative" }}>
          <LeftMenuButton
            styleCodes={{ backgroundColor: "rgb(251, 251, 251)" }}
            icon={{
              type: "link",
              icon: "/icons/ButtonStopwatch.svg",
            }}
            handleClick={() => setTimer(true)}
            iconWidth="44px"
            iconHeight="44px"
          />

          <div style={{ position: "absolute", top: "100px" }}>
            <Draggable style={{ top: "0px!important" }}>
              {timer && (
                <Timer
                  close={() => {
                    setTimer(false);
                  }}
                />
              )}
            </Draggable>
          </div>
          {preview && (
            <div>
              <LeftMenuButton
                icon={{
                  type: "link",
                  icon: "/icons/ButtonAnswer.svg",
                }}
                iconWidth="30px"
                iconHeight="30px"
                handleClick={() => {
                  setOpenAnswer(!openAnswer);
                }}
                styleCodes={{ backgroundColor: "rgb(251, 251, 251)" }}
              />
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          //   onClick={() => {
          //     setShowPageSelect(!showPageSelect);
          //   }}
          style={{
            minWidth: "60px",
            border: "1px solid #c9c9c9",
            padding: "5px 10px",
            borderRadius: "10px",
          }}
        >
          {!preview ? (
            <span>{page + "/" + book?.pages?.length}</span>
          ) : (
            <span>{previewCount + "/" + questions.length}</span>
          )}
        </button>
        <div className="right d-flex align-items-center mt-1">
          <div
            className="d-flex align-items-center"
            style={{
              backgroundColor: "rgba(76, 76, 76,0.6)",
              borderRadius: "40px",
            }}
          >
            <Buttons
              icon={{
                type: "link",
                icon: "/icons/ButtonPageMode.svg",
              }}
              handleClick={() => {
                closePreview();
                setPreviewCount(!preview);
              }}
              styleCodes={{
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                marginRight: "-10px",
                backgroundColor: preview ? "transparent" : "white",
                border: "none",
                cursor: "pointer",
              }}
              bgColor={!preview ? "gray" : "white"}
              disabled={!preview === true ? true : false}
            />

            <Buttons
              icon={{
                type: "link",
                icon: "/icons/ButtonPresentationMode.svg",
              }}
              handleClick={() => {
                setLastQuestionNow();
                closePreview();
              }}
              styleCodes={{
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                backgroundColor: !preview ? "transparent" : "white",
                border: "none",
                cursor: "pointer",
              }}
              disabled={preview}
              bgColor={preview ? "gray" : "white"}
            />
          </div>
        </div>
        <div style={{ position: "absolute", bottom: "40px", right: "90px" }}>
          <Buttons
            icon={{
              type: "svg",
              icon: (
                <svg
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  width={18}
                  height={18}
                >
                  <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"></path>
                </svg>
              ),
            }}
            handleClick={() => handleFullScreen()}
            styleCodes={{
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              boxShadow: "0px 0px 10px 0px rgb(0 0 0 / 30%)",
              border: "none",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </div>
  );
}
