import {openDB, deleteDB} from 'idb'

const indexDatabase = async (database, store, type, value, key) => {

    /*
        Öncelikle Database ve Store oluşturuyoruz.
        Eğer varsa tekrar oluşturmayacaklar.
    */
    const indexDb = await openDB(database, 1, {
        upgrade(db) {
            const checkInfo = db.objectStoreNames.contains('info')
            if (!checkInfo) db.createObjectStore('info')

            const checkPages = db.objectStoreNames.contains('pages')
            if (!checkPages) db.createObjectStore('pages')
        }
    })

    if (type === 'create') {
        try {
            const one = await indexDb.getKey(store, key)
            if (one) await indexDb.delete(store, key)
            await indexDb.add(store, value, key)
        } catch (error) {
            console.log(error)
        }
    }

    if (type === 'check') {
        return await indexDb.getKey(store, key)
    }

    if (type === 'remove') {
        await deleteDB(database, {})
    }

    if (type === 'detail') {
        return await indexDb.get(store, key)
    }

    if (type === 'list') {
        return await indexDb.getAll(store)
    }

    if (type === 'listKeys') {
        return await indexDb.getAllKeys(store)
    }
}

export default indexDatabase