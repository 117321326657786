const UploadLoader = ({
  percent,
  isPercent,
  text = "İçerik İndiriliyor..",
  subtext = "Lütfen Ekranı Kapatmayın",
  progress,
  status,
}) => {
  return (
    <div className={"globalUpload"} style={{ zIndex: 99999999999999 }}>
      {!isPercent ? (
        <>
          <h5 style={{ color: "white", fontSize: "1.5rem" }}>{text}</h5>
          <p style={{ color: "white", fontSize: "1.3rem" }}>{subtext}</p>
        </>
      ) : (
        <>
          <h5 style={{ color: "white" }}>İçerik İndiriliyor..</h5>
        </>
      )}
      <div
        style={{
          width: "50%",
          minHeight: "20px",
          alignSelf: "center",
          backgroundColor: "white",
          position: "relative",
          borderRadius: "10px",
        }}
      >
        <span
          style={{
            backgroundColor: "#1d71b8",
            minHeight: "20px",
            minWidth: progress + "%",
            position: "absolute",
            left: "0",
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "10px",
            borderRadius: "10px",
          }}
        >
          {progress} %
        </span>
      </div>
      {progress === 100 && status === false ? "Dosya İşleniyor" : ""}
    </div>
  );
};

export default UploadLoader;
