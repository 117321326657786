/*
    Bu alanda, Sunucu üzerinde bulunan kitapların indirilmesi
    ve bu kitapların Base64 formatına dönüştürülmesi işlemi yapılmaktadır.
*/

import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import Navbar from '../Components/Navbar'
import BookOneSkeleton from '../Components/BookOneSkeleton'
import BookOne from '../Components/BookOne'

import BookImageToBase64 from '../Helpers/BookImageToBase64'
import localDatabase from '../Helpers/IndexDatabase'

import Network from '../Helpers/Network'
import Swal from 'sweetalert2'
import { saveAs } from "file-saver"

const HomeScreen = () => {
    const navigate = useNavigate();
    const skeletonTimes = [1, 2, 3, 4, 5, 6, 7, 8]

    const [list, setList] = useState([])
    const [ready, setReady] = useState(false)

    const [exportUi, setExportUi] = useState(false)
    const [downloadUi, setDownloadUi] = useState(false)
    const [totalPages, setTotalPages] = useState(0)
    const [processPages, setProcessPages] = useState(0)

    const [load, setLoad] = useState(0)

    const reloadWindow = () => {
        window.location.reload()
    }

    const mountData = () => {
        localDatabase('listAll')
            .then(response => {
                const data = []
                response.forEach(item => {
                    data.push(JSON.parse(item))
                })
                setList([{ id: 9000, upload: true }, ...data])
                setReady(true)
            })
    }

    useEffect(() => {
        mountData()
    }, [])

    const startNavigate = async id => {
        const checkDatabase = await localDatabase('one', id)
        if (!checkDatabase) {
            await startDownload(id, true)
        } else {
            navigate(`/board?book=${id}`)
        }
    }

    const startDownload = async (id, route = false) => {
        // İndirilen kitap, Base64'e çevrilir ve Index Database üzerine kayıt edilir.
        // Eğer bu kitap mevcutta varsa, tekrar indirilip tekrar işleme girer ve güncellenir.

        try {
            setDownloadUi(true)
            const bookData = await Network.get(`pages/books/${id}`) // Kitabı, Download eder.
            const pages = bookData.pages.splice(0, 20)
            setTotalPages(pages.length)

            // Kitabın Download işlemi tamamlandıktan sonra her sayfanın tek tek Base64 formatına getirilmesi gerekir.
            const thisPages = []
            let percentStart = 1;
            for (let file of pages) {
                setProcessPages(percentStart)
                const base64 = await BookImageToBase64(file.file)
                thisPages.push({
                    ...file,
                    image: base64
                })
                percentStart = percentStart + 1
            }

            const thisBook = {
                ...bookData,
                pages: thisPages
            }

            const checkDatabase = await localDatabase('one', id)
            if (checkDatabase) {
                await localDatabase('remove', id)
            }
            await localDatabase('create', thisBook)

            setTimeout(() => {
                setLoad(load + 1)
                setDownloadUi(false)
                setTotalPages(0)
                setProcessPages(0)
            }, 10000)

            if (route) {
                navigate(`/board?book=${id}`)
            }
        } catch (error) {
            console.log(error)
            setDownloadUi(false)
            setTotalPages(0)
            setProcessPages(0)
        }
    }

    const startBuild = async id => {
        try {
            setExportUi(true)
            // Index Database üzerinde yazılı bulunan Kitap, Export alınır.
            const thisBook = await localDatabase('oneDetail', id)
            const blob = new Blob([thisBook], { type: 'application/json' })
            saveAs(blob, `book-${id}.json`)
            setTimeout(() => {
                setExportUi(false)
            }, 10000)
        } catch (error) {
            console.log(error)
        }
    }

    const startRemove = async id => {
        // Index Database üzerinden silinir.
        Swal.fire({
            title: "Bir Saniye",
            text: "Daha önce indirilmiş kitabı kaldıracak mısınız?",
            icon: "warning",
            confirmButtonText: "Evet",
            confirmButtonColor: "#9A0606",
            showCancelButton: true,
            cancelButtonText: "Hayır",
            cancelButtonColor: "#242424",
        }).then(async (response) => {
            if (response.isConfirmed) {
                await localDatabase('remove', id)
                setLoad(load + 1)
                mountData()
            }
        });
    }

    const addBook = async () => {
        document.getElementById('file-upload').click()
    }

    const importFiles = async event => {
        const thisFile = event.target.files[0]
        const reader = new FileReader()
        reader.onload = async event => {
            const jsonData = event.target.result
            const parsedData = JSON.parse(jsonData)
            const checkOne = await localDatabase('one', parsedData.id)
            if (!checkOne) {
                await localDatabase('create', parsedData)
                await mountData()
                Swal.fire({
                    title: 'İçerik Yüklemesi Tamamlandı',
                    text: `${parsedData.name} isimli kitabın yüklemesi tamamlandı!`,
                    icon: 'success',
                    confirmButtonText: 'Harika!'
                })
            } else {
                await localDatabase('remove', parsedData.id).then(async (res) => {
                    await localDatabase('create', parsedData)
                    await mountData()
                    Swal.fire({
                        title: 'İçerik Yüklemesi Tamamlandı',
                        text: `${parsedData.name} isimli kitabın yüklemesi tamamlandı!`,
                        icon: 'success',
                        confirmButtonText: 'Harika!'
                    })
                })

            }
        }
        await reader.readAsText(thisFile)
    }

    return (
        <div className="container">
            <input className={"d-none"} id={"file-upload"} multiple={false} type="file" accept={".json"} onChange={(event) => { importFiles(event) }} />
            <Navbar menu={'downloads'} />
            <div className="row mt-5">
                {
                    !ready ?
                        skeletonTimes.map(item => {
                            return (
                                <div key={`book-${item}`} className="col-8 offset-2 col-sm-6 offset-sm-0 col-md-6 col-lg-4 col-xl-3">
                                    <BookOneSkeleton />
                                </div>
                            )
                        })
                        :
                        list.map(item => {
                            return (
                                <div key={`book-${item.id}`} className="col-8 offset-2 col-sm-6 offset-sm-0 col-md-6 col-lg-4 col-xl-3">
                                    <BookOne
                                        book={item}
                                        download={() => { startDownload(item.id) }}
                                        build={() => { startBuild(item.id) }}
                                        remove={() => { startRemove(item.id) }}
                                        load={load}
                                        navigate={() => { startNavigate(item.id) }}
                                        upload={item.upload}
                                        add={() => { addBook() }}
                                    />
                                </div>
                            )
                        })
                }
            </div>
            {
                downloadUi &&
                <div className={"download-ui"}>
                    <p>Kitap kullanıma hazırlanıyor.</p>
                    <label>Bu işlem internet hızınıza göre farklılık gösterebilir.</label>
                    <p>({totalPages} / {processPages}) Sayfalar İşleniyor.</p>
                    <button onClick={() => { reloadWindow() }}>
                        Çalışmayı Durdur
                    </button>
                </div>
            }
            {
                exportUi &&
                <div className={"download-ui"}>
                    <p>Kitap dışarı aktarılıyor.</p>
                    <label>Bu işlem bilgisayarınızın hızına göre değişkenlik gösterebilir.</label>
                    <p>Lütfen dosyanın sorunsuz şekilde bilgisayarınıza kayıt olmasını bekleyin.</p>
                </div>
            }
        </div>
    )
};

export default HomeScreen