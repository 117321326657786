import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import HomeScreen from './screens/HomeScreen'
import DownloadsScreen from './screens/DownloadsScreen'
import BoardScreen from './screens/BoardScreen'

const App = () => {
  return (
      <Router>
          <Routes>
              <Route exact path="/" element={<HomeScreen />} />
              <Route path="/downloads" element={<DownloadsScreen />} />
              <Route path="/board" element={<BoardScreen />} />
          </Routes>
      </Router>
  )
}

export default App
