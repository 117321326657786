import { useRef } from "react";
import LeftMenuButton from "../LeftMenuButton";
import React, { useState } from "react";
import { useEffect } from "react";
import Timer from "../Timer";
import { Draggable } from "drag-react";
import Buttons from "../Buttons";
export default function BottomToolbar({
  preview,
  brushColorChange = (color) => {},
  brushSizeChange = (size) => {},
  brushRadius,
  onChangeMode = (mode) => {},
  closePreview = () => {},
  setLastQuestionNow = () => {},
  setPreviewCount = (index) => {},
  trashSizeChange,
  trashRadius,
  openAnswer,
  setOpenAnswer,
  questions = [],
  previewCount,
  page,
  book,
  handleChangePage,
  FullScreen,
  setFullScreen,
}) {
  const [Key, setKey] = useState("");
  const [Color, setColor] = useState("#000");
  const [showColorPalette, setShowColorPalette] = useState(false);
  const [openTrashWidth, setOpenTrashWidth] = useState(false);
  const [showShapes, setShowShapes] = useState(false);
  const [timer, setTimer] = useState(false);
  const colors = [
    "#ff0000",
    "#00ff00",
    "#0000ff",
    "#ffff00",
    "#ff00ff",
    "#00ffff",
    "#000000",
    "#ffffff",
  ];
  const colorPaletteRef = useRef();
  const eraserPaletteRef = useRef();
  const menuRef = useRef();
  const shapeRef = useRef();

  const handleClickOutside = (event) => {
    const popups = document.querySelectorAll(".popup");
    const clickedInsidePopup = Array.from(popups).some((popup) =>
      popup.contains(event.target)
    );
    if (!clickedInsidePopup) {
      setKey("");
    }
    if (
      colorPaletteRef.current &&
      !colorPaletteRef.current.contains(event.target)
    ) {
      setShowColorPalette(false);
    }
    if (
      eraserPaletteRef.current &&
      !eraserPaletteRef.current.contains(event.target)
    ) {
      setOpenTrashWidth(false);
    }
    if (shapeRef.current && !shapeRef.current.contains(event.target)) {
      setShowShapes(false);
    }
  };
  const handleNextPage = () => {
    if (preview) {
      setPreviewCount(previewCount + 1);
    } else {
      if (page < book.pages.length) {
        handleChangePage(page + 1);
      }
    }
  };
  const handlePrevPage = () => {
    if (preview) {
      if (previewCount > 1) {
        setPreviewCount(previewCount - 1);
      }
    } else {
      if (page > 1) {
        handleChangePage(page - 1);
      }
    }
  };
  const handleFullScreen = () => {
    let element = document.documentElement;
    if (!FullScreen) {
      setFullScreen(true);
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }

    if (FullScreen) {
      setFullScreen(false);
      // document.querySelector(".mobile-warn").style.display = "block";
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      className="bottom-toolbar"
      style={{
        position: "absolute",
        width: "100%",
        minHeight: "100px",
        padding: "0px 10px",
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "white",
        color: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        zIndex: 9999999999,
        backdropFilter: "blur(16px)",
      }}
    >
      <div
        style={{
          padding: "15px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", padding: "0px 10px" }}
        >
          <div className="right d-flex align-items-center mt-1">
            <div
              className="d-flex align-items-center"
              style={{
                backgroundColor: "rgba(76, 76, 76,0.6)",
                borderRadius: "40px",
                marginRight: "15px",
              }}
            >
            
              <Buttons
                icon={{
                  type: "link",
                  icon: "/icons/ButtonPageMode.svg",
                }}
                handleClick={() => {
                  closePreview();
                  setPreviewCount(!preview);
                }}
                styleCodes={{
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  marginRight: "-10px",
                  backgroundColor: preview ? "transparent" : "white",
                  border: "none",
                  cursor: "pointer",
                }}
                bgColor={!preview ? "gray" : "white"}
                disabled={!preview === true ? true : false}
              />

              <Buttons
                icon={{
                  type: "link",
                  icon: "/icons/ButtonPresentationMode.svg",
                }}
                handleClick={() => {
                  setLastQuestionNow();
                  closePreview();
                }}
                styleCodes={{
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  backgroundColor: !preview ? "transparent" : "white",
                  border: "none",
                  cursor: "pointer",
                }}
                disabled={preview}
                bgColor={preview ? "gray" : "white"}
              />
            </div>
          </div>

          <LeftMenuButton
            icon={{
              type: "link",
              icon: "/icons/ButtonBack.svg",
            }}
            handleClick={() => {
              handlePrevPage();
            }}
            styleCodes={{ margin: 0 }}
          />
          <button
            //   onClick={() => {
            //     setShowPageSelect(!showPageSelect);
            //   }}
            style={{
              minWidth: "50px",
              height: "40px",
              borderRadius: "10px",
              margin: "5px 10px",
              border: "1px solid #c9c9c9",
            }}
          >
            {!preview ? (
              <span>{page + "/" + book?.pages?.length}</span>
            ) : (
              <span>{previewCount + "/" + questions.length}</span>
            )}
          </button>
          <LeftMenuButton
            icon={{
              type: "link",
              icon: "/icons/ButtonForward.svg",
            }}
            handleClick={() => {
              handleNextPage();
            }}
            styleCodes={{ margin: 0 }}
          />
        </div>
        <div style={{ marginRight: "15px" }}>
          <Buttons
            icon={{
              type: "svg",
              icon: (
                <svg
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  width={18}
                  height={18}
                >
                  <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"></path>
                </svg>
              ),
            }}
            handleClick={() => handleFullScreen()}
            styleCodes={{
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              boxShadow: "0px 0px 10px 0px rgb(0 0 0 / 30%)",
              border: "none",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "start",
          borderTop: "1px solid black",
          paddingTop: "15px",
        }}
      >
        <div style={{ display: "flex", marginLeft: "15px" }}>
          <LeftMenuButton
            styleCodes={{
              backgroundColor: "rgb(251, 251, 251)",
              marginRight: "10px",
            }}
            icon={{
              type: "link",
              icon: "/icons/ButtonHand.svg",
            }}
            handleClick={() => onChangeMode("move")}
            iconWidth="44px"
            iconHeight="44px"
          />

          <LeftMenuButton
            styleCodes={{
              backgroundColor: "rgb(251, 251, 251)",
              marginRight: "10px",
            }}
            icon={{
              type: "link",
              icon: "/icons/ButtonPen.svg",
            }}
            handleClick={() => {
              onChangeMode("paint2");
              setKey("pen2");
              setShowColorPalette(!showColorPalette);
            }}
            iconWidth="44px"
            iconHeight="44px"
          />
          <LeftMenuButton
            styleCodes={{
              backgroundColor: "rgb(251, 251, 251)",
              marginRight: "10px",
            }}
            icon={{
              type: "link",
              icon: "/icons/ButtonMarker.svg",
            }}
            handleClick={() => {
              onChangeMode("paint");
              setKey("pen");
              setShowColorPalette((prev) => !prev);
            }}
            iconWidth="44px"
            iconHeight="44px"
          />
          {showColorPalette && (
            <div className={"b-color-palette"} ref={colorPaletteRef}>
              {colors.map((color, index) => (
                <div
                  key={`color-palette-${index}`}
                  style={{
                    backgroundColor: color,
                    width: "18px",
                    height: "18px",
                    borderRadius: "18px",
                    display: "inline-block",
                    margin: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    brushColorChange(color);
                    setColor(color);
                  }}
                />
              ))}
              <span
                className="brush-line popup"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100px"
                  height="10.000000pt"
                  viewBox="0 0 94.000000 10.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,10.000000) scale(0.100000,-0.100000)"
                    fill={Color}
                    stroke={Color}
                    strokeWidth={brushRadius}
                  >
                    <path
                      d="M535 80 c-27 -5 -70 -17 -95 -26 -65 -23 -264 -30 -360 -11 -71 14
 -91 14 -78 1 3 -3 46 -12 95 -21 114 -20 247 -12 372 22 129 34 307 34 444 0
 15 -4 27 -3 27 3 0 27 -301 51 -405 32z"
                    ></path>
                  </g>
                </svg>
              </span>
              <input
                type="range"
                min="1"
                max="41"
                defaultValue="10"
                value={brushRadius}
                step={10}
                id="brush-line"
                className="range range-000000"
                style={{ width: "100%", backgroundColor: Color }}
                onInput={(e) => {
                  brushSizeChange(parseInt(e.target.value));
                }}
              ></input>
            </div>
          )}
          <LeftMenuButton
            styleCodes={{
              backgroundColor: "rgb(251, 251, 251)",
              marginRight: "10px",
            }}
            icon={{
              type: "link",
              icon: "/icons/ButtonEraser.svg",
            }}
            handleClick={() => {
              onChangeMode("trash");
              setKey("trash");
              setOpenTrashWidth(!openTrashWidth);
              const canvas = document.querySelectorAll(".upper-canvas");

              for (let i = 0; i < canvas.length; i++) {
                canvas[
                  i
                ].style.cursor = `url("data:image/svg+xml;base64,CgkJPHN2ZwoJCQloZWlnaHQ9IjUwIgoJCQlmaWxsPSIjMDAwMDAwMTAiCgkJCXN0cm9rZT0iYmxhY2siCgkJCXN0cm9rZS13aWR0aD0iNSIKCQkJdmlld0JveD0iMCAwIDEwNSAxMDUiCgkJCXdpZHRoPSI1MCIKCQkJeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgoJCT4KCQkJPGNpcmNsZQoJCQkJY3g9IjUwJSIKCQkJCWN5PSI1MCUiCgkJCQlyPSI1MCIgCgkJCS8+CgkJPC9zdmc+Cgk=") 100 100`;
              }
            }}
            iconWidth="44px"
            iconHeight="44px"
          />
          {openTrashWidth && (
            <label
              className={`b-eraser-palette popup ${
                Key === "trash" && "active"
              }`}
              ref={eraserPaletteRef}
            >
              <span className="trash-width">
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100px"
                  height="10.000000pt"
                  viewBox="0 0 94.000000 10.000000"
                  preserveAspectRatio="xMidYMid meet"
                  stroke="#000"
                >
                  <g
                    transform="translate(0.000000,10.000000) scale(0.100000,-0.100000)"
                    strokeWidth={trashRadius}
                  >
                    <path
                      d="M535 80 c-27 -5 -70 -17 -95 -26 -65 -23 -264 -30 -360 -11 -71 14
 -91 14 -78 1 3 -3 46 -12 95 -21 114 -20 247 -12 372 22 129 34 307 34 444 0
 15 -4 27 -3 27 3 0 27 -301 51 -405 32z"
                    ></path>
                  </g>
                </svg>
              </span>
              <input
                type="range"
                min="1"
                max="100"
                defaultValue="10"
                value={trashRadius}
                style={{ width: "100%", backgroundColor: Color }}
                onInput={(e) => {
                  trashSizeChange(parseInt(e.target.value));
                }}
              ></input>
            </label>
          )}
          <LeftMenuButton
            styleCodes={{
              backgroundColor: "rgb(251, 251, 251)",
              marginRight: "10px",
            }}
            icon={{
              type: "link",
              icon: "/icons/ButtonShapes.svg",
            }}
            handleClick={() => {
              setShowShapes(!showShapes);
            }}
            iconWidth="44px"
            iconHeight="44px"
          />
          {showShapes && (
            <div
              className={
                "b-shapes-palette d-flex justify-content-between align-items-center flex-wrap"
              }
              ref={shapeRef}
            >
              <LeftMenuButton
                icon={{
                  type: "link",
                  icon: "/icons/line.svg",
                }}
                handleClick={() => onChangeMode("line")}
                styleCodes={{ backgroundColor: "#c9c9c984" }}
              />
              <LeftMenuButton
                icon={{
                  type: "link",
                  icon: "/icons/rectangle.svg",
                }}
                handleClick={() => onChangeMode("rect")}
                styleCodes={{ backgroundColor: "#c9c9c9" }}
              />
              <LeftMenuButton
                icon={{
                  type: "link",
                  icon: "/icons/circle.svg",
                }}
                handleClick={() => onChangeMode("circle")}
                styleCodes={{ backgroundColor: "#c9c9c9" }}
              />
              <LeftMenuButton
                icon={{
                  type: "link",
                  icon: "/icons/triangle.svg",
                }}
                handleClick={() => onChangeMode("triangle")}
                styleCodes={{ backgroundColor: "#c9c9c9" }}
              />
            </div>
          )}
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", top: "0" }}>
              <Draggable style={{ top: "0px!important" }}>
                {timer && (
                  <Timer
                    close={() => {
                      setTimer(false);
                    }}
                  />
                )}
              </Draggable>
            </div>
            <LeftMenuButton
              styleCodes={{
                backgroundColor: "rgb(251, 251, 251)",
                marginRight: "10px",
              }}
              icon={{
                type: "link",
                icon: "/icons/ButtonStopwatch.svg",
              }}
              handleClick={() => setTimer(true)}
              iconWidth="44px"
              iconHeight="44px"
            />
          </div>
          {preview && (
            <LeftMenuButton
              icon={{
                type: "link",
                icon: "/icons/ButtonAnswer.svg",
              }}
              iconWidth="30px"
              iconHeight="30px"
              handleClick={() => {
                setOpenAnswer(!openAnswer);
              }}
              styleCodes={{
                backgroundColor: "rgb(251, 251, 251)",
                marginRight: "10px",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
