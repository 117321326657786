import React from "react";

export default function LeftMenuButton({
  icon,
  handleClick,
  styleCodes,
  text = false,
  iconWidth = "100%",
  iconHeight = "30px",
  bgColor = "rgb(76, 76, 76)",
  disabled = false,
  id = '',
}) {
  return (
    <button
      id={id ? id : Math.round(Math.random() * 9999999)}
      className="left-menu-button d-flex flex-column justify-content-center align-items-center"
      onClick={handleClick}
      style={styleCodes}
      disabled={disabled}
    >
      {icon.type === "svg" ? (
        icon.icon
      ) : (
        <div
          style={{
            height: iconHeight,
            width: iconWidth,
            WebkitMask: `url(${icon.icon}) center center / contain no-repeat`,
            backgroundColor: bgColor,
          }}
        ></div>
      )}
    </button>
  );
}
