import React from "react";

const RangeComponent = ({ onclick, borderColor = "#000", brushRadius }) => {
  return (
    <div className="range-buttons d-flex flex-1 justify-content-between align-items-center w-100 p-2">
      <span
        onClick={() => onclick(5 * 4)}
        style={{
          width: "5px",
          height: "5px",
          borderRadius: "50%",
          border: "1px solid",
          borderColor: borderColor,
          backgroundColor: brushRadius === 20 && borderColor,
        }}
      ></span>
      <span
        onClick={() => onclick(10 * 4)}
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          border: "1px solid",
          borderColor: borderColor,
          backgroundColor: brushRadius === 40 && borderColor,
        }}
      ></span>
      <span
        onClick={() => onclick(15 * 4)}
        style={{
          width: "15px",
          height: "15px",
          borderRadius: "50%",
          border: "1px solid",
          borderColor: borderColor,
          backgroundColor: brushRadius === 60 && borderColor,
        }}
      ></span>
      <span
        onClick={() => onclick(20 * 4)}
        style={{
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          border: "1px solid",
          borderColor: borderColor,
          backgroundColor: brushRadius === 80 && borderColor,
        }}
      ></span>
      <span
        onClick={() => onclick(25 * 4)}
        style={{
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          border: "1px solid",
          borderColor: borderColor,
          backgroundColor: brushRadius === 100 && borderColor,
        }}
      ></span>
    </div>
  );
};

export default RangeComponent;
