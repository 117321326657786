import React from "react";

export default function MenuButton({
  icon,
  handleClick,
  styleCodes,
  text = false,
  iconWidth = "30px",
  iconHeight = "30px",
  bgColor = "rgb(76, 76, 76)",
}) {
  return (
    <button
      className="default-button d-flex justify-content-center align-items-center"
      onClick={handleClick}
      style={styleCodes && styleCodes}
    >
      {icon.type === "svg" ? (
        icon.icon
      ) : (
        <div
          style={{
            height: iconHeight,
            width: iconWidth,
            WebkitMask: `url(${icon.icon}) center center / contain no-repeat`,
            backgroundColor: bgColor,
          }}
        ></div>
      )}
      <p>{text}</p>
    </button>
  );
}
