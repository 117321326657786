// Resmi Base64'e dönüştürmek için bir fonksiyon
export default async function convertImageToBase64(url) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width, img.height);
            const base64Data = canvas.toDataURL("image/png"); // veya "image/jpeg" vb.
            resolve(base64Data);
        };
        img.onerror = reject;
        img.src = url;
    });
}