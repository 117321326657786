import React, {useEffect, useState, useRef} from 'react';
import {useLocation} from 'react-router-dom';

import Network from '../Helpers/Network';
import Menu from '../Components/Menu';
import DrawPanel from '../Components/DrawPanel';
import IndexDatabase from '../Helpers/IndexDatabase';
import Answers from '../Components/Answers/Answers';
import MobilePagination from '../Components/MobilePagination/MobilePagination';
import BottomToolbar from '../Components/BottomToolbar/BottomToolbar';
import RightToolbar from '../Components/RightToolbar/RightToolbar';
import {Draggable} from 'drag-react';
import UploadLoader from '../Components/UploadLoader/UploadLoader';
import indexDatabase from '../Helpers/IndexDatabase'

let currentRef = {};
const BoardScreen = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const bookId = queryParams.get('book');
    const drawPanelRef = useRef(null);
    const [openAnswer, setOpenAnswer] = useState(false);
    const [book, setBook] = useState({});
    const [data, setData] = useState([]);
    const [background, setBackground] = useState('');
    const [infos, setInfos] = useState([])
    const [questions, setQuestions] = useState([]);
    const [answer, setAnswer] = useState({});
    const [FullScreen, setFullScreen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [percent, setPercent] = useState(0);
    const [isPercent, setIsPercent] = useState(false);
    const [wait, setWait] = useState(false);
    const answersRef = useRef();
    let bool = false;
    useEffect(() => {
        if (!bool) {
            bool = true;
            mountData();
        }
        setBg();
    }, []);
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (answersRef.current && !answersRef.current.contains(event.target)) {
            setOpenAnswer(false);
        }
    };

    const setBg = () => {
        const getBackground = localStorage.getItem('background');
        if (getBackground) {
            setBackground(`/bg-${getBackground}.jpg`);
            // switch (getBackground) {
            //   case "1":
            //     setBackground("/bg-1.jpeg");
            //     break;
            //   case "2":
            //     setBackground("/bg-2.png");
            //     break;
            //   case "3":
            //     setBackground("/bg-3.jpg");
            //     break;
            //   default:
            //     setBackground("/bg-1.jpeg");
            // }
        } else {
            setBackground('/bg-2.jpg');
        }
    };

    const areaInput = useRef(null);
    const [brushColor, setBrushColor] = useState('#000000');
    const [brushRadius, setBrushRadius] = useState(10);
    const [cursorStyle, setCursorStyle] = useState('pointer');
    const [trashRadius, setTrashRadius] = useState(25);
    const [mode, setMode] = useState('paint');
    const [currentPage, setPage] = useState(1);
    const localCurrentPage = JSON.parse(
        localStorage.getItem(bookId)
    )?.currentPage;
    const localPreviewCount = JSON.parse(
        localStorage.getItem(bookId)
    )?.previewCount;
    const [previewSrc, setPreviewSrc] = useState(null);
    const [preview, setPreview] = useState(false);
    const [newPaper, setNewPaper] = useState(false);
    const [lastQuestion, setLastQuestion] = useState(1);
    const [previewCount, setPreviewCount] = useState(0);
    const zoomInput = useRef(null);
    const newPageInput = useRef(null);

    const [renderPage, setRenderPage] = useState({})

    const mountAndRender = async pageNumber => {
        let thisPage = await indexDatabase(`book-${bookId}`, 'pages', 'detail', {}, `page-${pageNumber}`)
        thisPage = JSON.parse(thisPage)
        const page = thisPage.base64String

        const zoomMarkers = []
        const questions = []
        let questionIndex = 1
        thisPage.thisQuestions?.forEach((qu, quI) => {
            const quest = {
                x : qu.x,
                y : qu.y,
                w : qu.width,
                h : qu.height,
                p : pageNumber,
                i : questionIndex,
                oi : qu.order,
                image : qu.image,
                answer : qu.answer,
                video : qu.video
            }
            zoomMarkers.push(quest)
            questions.push(quest)
            questionIndex = questionIndex + 1
        })

        let staticIndex = questions.length
        let infoIndex = 1
        thisPage.thisInfos?.forEach((qu, quI) => {
            const info = {
                x : qu.x,
                y : qu.y,
                w : qu.width,
                h : qu.height,
                p : pageNumber,
                i : infoIndex,
                oi : qu.order,
                image : qu.image,
                answer : qu.answer,
                video : qu.video
            }
            zoomMarkers.push(info)
            questions.push(info)
            infoIndex = infoIndex + 1
        })

        zoomMarkers.sort(function(a, b) {
            return a.order - b.order;
        });

        const final = []
        zoomMarkers.forEach((item, index) => {
            final.push({
                ...item,
                i: index + 1
            })
        })

        const lastQuestions = []
        questions.forEach((item, index) => {
            lastQuestions.push({
                ...item,
                i: index + 1
            })
        })

        lastQuestions.sort(function(a, b) {
            return a.oi - b.oi;
        });

        // Ekrana basılacak olan veri.
        const renderPage = {
            page: pageNumber,
            src: page,
            zoomMarkers: final
        }

        setQuestions(lastQuestions)
        setRenderPage(renderPage)
        await handleChange(pageNumber - 1)
    }

    const mountData = async () => {
        /*
            Önceden bu alanda Download yapıyorduk ancak artık yapmıyoruz.
            Bu yüzden bu alanda Refactor yapıldı.
            Eğer eski kodlara bakmak isterseniz, "Refactor BoardScreen" isimli Branch öncesine bakınız.

            Şimdi, burada tüm kitabı öncelikle hazırlıyoruz.
            Sonra ise kullanıma açıyoruz.
        */

        setWait(true)

        // Bu kitaba ait İnfo bilgileri aldık.
        const one = await indexDatabase(`book-${bookId}`, 'info', 'detail', {}, 'book')
        const thisBook = JSON.parse(one)

        // Son kalan yeri yakalamak için LocalStorage desteği kullandık.
        const localStorageGetItem = await localStorage.getItem(bookId)
        if (!localStorageGetItem) {
            await localStorage.setItem(bookId, JSON.stringify({ currentPage, previewCount }))
        }


        // Burada artık sayfa bilgilerini alma vakti geldi.
        const allPages = await indexDatabase(`book-${bookId}`, 'pages', 'listKeys', {}, '')
        thisBook.pages = allPages
        setBook(thisBook)
        setData(allPages)

        try {
            await mountAndRender(1)
        } catch (e) {
            console.log(e)
        }
        setWait(false)
    };

    const handleChangePage = async (page) => {
        if (!preview) {
            try {
                await mountAndRender(page)
                setPage(page)
                setTimeout(() => {
                    setMode({type : 'paint2', updated : Math.round(Math.random() * 99999)});
                }, 100)
                localStorage.setItem(
                    bookId,
                    JSON.stringify({currentPage : page, previewCount : localPreviewCount})
                )
            } catch (e) {
                console.log(e)
            }
        }
    };

    const handleChange = async (index) => {
        var elements = areaInput.current.children;
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            element.style.display = i === index ? 'block' : 'none';
        }
    };

    useEffect(() => {
        if (previewSrc == null) {
            areaInput.current.style.display = 'block';
            zoomInput.current.style.display = 'none';
            newPageInput.current.style.display = 'none';
            setPreview(false);
        } else {
            areaInput.current.style.display = 'none';
            zoomInput.current.style.display = 'block';
            newPageInput.current.style.display = 'none';
            setPreview(true);
        }
    }, [previewSrc]);

    useEffect(() => {
        if (newPaper) {
            areaInput.current.style.display = 'none';
            zoomInput.current.style.display = 'none';
            newPageInput.current.style.display = 'block';
        } else {
            areaInput.current.style.display = 'block';
            zoomInput.current.style.display = 'none';
            newPageInput.current.style.display = 'none';
        }
    }, [newPaper]);

    const handeZoom = (imageSrc) => {
        setPreviewSrc(imageSrc);
    };

    const [pageStatus, setPageStatus] = useState();

    const handlePageStatus = (status) => {
        //Page Status == 0 => Expand
        //Page Status == 1 => Fit
        setPageStatus(status);
    };

    const [newPreviewCoords, setNewPreviewCoords] = useState(null);
    const [newPreviewSrc, setNewPreviewSrc] = useState(null);

    const changePreviewPage = async (index) => {
        if (!index) {
            setPreview(false);
        } else {
            if (index <= questions.length && index > 0) {
                let thisQuestion = questions.filter((x) => x.oi === index);
                thisQuestion = thisQuestion[0];
                if (currentRef != null) {
                    const nextProp = await currentRef.showPreview(thisQuestion.oi)
                    if (nextProp) {
                        setPreviewSrc(nextProp);
                        setPreviewCount(index);
                        localStorage.setItem(
                            bookId,
                            JSON.stringify({
                                currentPage : localCurrentPage,
                                previewCount : index
                            })
                        );
                        setLastQuestion(thisQuestion);
                    }
                }
            }
        }
    };

    return (
        <div className="container2">
            {wait && <UploadLoader percent={percent} isPercent={isPercent}/>}
            <Draggable>
                <Answers
                    referans={answersRef}
                    setOpenAnswer={setOpenAnswer}
                    openAnswer={openAnswer}
                    answerData={answer}
                />
            </Draggable>

            <RightToolbar
                brushRadius={brushRadius}
                onChangeMode={(mode) =>
                    setMode({type : mode, updated : Math.round(Math.random() * 99999)})
                }
                brushColorChange={(color) => setBrushColor(color)}
                brushSizeChange={setBrushRadius}
                trashSizeChange={setTrashRadius}
                trashRadius={trashRadius}
                openAnswer={openAnswer}
                setOpenAnswer={setOpenAnswer}
                preview={preview}
                questions={questions}
                page={currentPage}
                book={book}
                previewCount={previewCount}
                setPreviewCount={(index) => {
                    changePreviewPage(index);
                }}
                setLastQuestionNow={() => {
                    changePreviewPage(lastQuestion.oi || 1);
                }}
                closePreview={() => setPreviewSrc(null)}
                FullScreen={FullScreen}
                setFullScreen={setFullScreen}
            />
            <BottomToolbar
                brushRadius={brushRadius}
                onChangeMode={(mode) =>
                    setMode({type : mode, updated : Math.round(Math.random() * 99999)})
                }
                brushColorChange={(color) => setBrushColor(color)}
                brushSizeChange={setBrushRadius}
                trashSizeChange={setTrashRadius}
                trashRadius={trashRadius}
                openAnswer={openAnswer}
                setOpenAnswer={setOpenAnswer}
                preview={preview}
                questions={questions}
                page={currentPage}
                book={book}
                previewCount={previewCount}
                setPreviewCount={(index) => {
                    changePreviewPage(index);
                }}
                setLastQuestionNow={() => {
                    changePreviewPage(lastQuestion.oi || 1);
                }}
                closePreview={() => setPreviewSrc(null)}
                handleChangePage={handleChangePage}
                FullScreen={FullScreen}
                setFullScreen={setFullScreen}
            />
            <MobilePagination
                handleChangePage={handleChangePage}
                previewCount={previewCount}
                setPreviewCount={(index) => {
                    changePreviewPage(index);
                }}
                page={currentPage}
                preview={preview}
                book={book}
                FullScreen={FullScreen}
                setFullScreen={setFullScreen}
            />
            <Menu
                answer={answer}
                setAnswer={setAnswer}
                FullScreen={FullScreen}
                setFullScreen={setFullScreen}
                setLastQuestionNow={() => {
                    changePreviewPage(lastQuestion.oi || 1);
                }}
                questions={questions}
                brushColorChange={(color) => setBrushColor(color)}
                onChangeMode={(mode) =>
                    setMode({type : mode, updated : Math.round(Math.random() * 99999)})
                }
                brushSizeChange={setBrushRadius}
                brushRadius={brushRadius}
                setCursorStyle={setCursorStyle}
                trashSizeChange={setTrashRadius}
                trashRadius={trashRadius}
                handleChangePage={handleChangePage}
                page={currentPage}
                setPage={setPage}
                maxPage={data.length}
                book={book}
                closePreview={() => setPreviewSrc(null)}
                preview={preview}
                triggerBg={() => {
                    setBg();
                }}
                onNewPage={() => setNewPaper(!newPaper)}
                background={background}
                handlePageStatus={handlePageStatus}
                previewCount={previewCount}
                setPreviewCount={(index) => {
                    changePreviewPage(index);
                }}
                openAnswer={openAnswer}
                setOpenAnswer={setOpenAnswer}
            />
            <div
                className="drawing-board"
                ref={zoomInput}
                style={
                    !preview
                        ? {
                            display : 'none',
                            backgroundImage : `url("${background}")`,
                            backgroundPosition : 'center center',
                            backgroundSize : '100% 100%',
                            backgroundRepeat : 'no-repeat',
                            objectFit : 'cover'
                        }
                        : {backgroundColor : 'white'}
                }
            >
                {preview ? (
                    <div>
                        <DrawPanel
                            currentPage={currentPage}
                            preview={true}
                            id="handleZoom"
                            activeMode={mode}
                            brushRadius={brushRadius}
                            cursorStyle={cursorStyle}
                            trashRadius={trashRadius}
                            brushColor={brushColor}
                            imgSrc={previewSrc}
                            zoomMarkers={null}
                            isActive={true}
                            setPreviewCount={(index) => {
                                changePreviewPage(index);
                            }}
                            newPreviewCoords={newPreviewCoords}
                            questions={questions}
                            // ref={drawPanelRef}
                        />
                    </div>
                ) : null}
            </div>
            <div
                className="drawing-board"
                ref={newPageInput}
                style={
                    !newPaper
                        ? {
                            display : 'none',
                            backgroundImage : `url("${background}")`,
                            backgroundPosition : 'center center',
                            backgroundSize : '100% 100%',
                            backgroundRepeat : 'no-repeat',
                            objectFit : 'cover'
                        }
                        : {backgroundColor : 'white'}
                }
            >
                {newPaper ? (
                    <div>
                        <DrawPanel
                            currentPage={currentPage}
                            id="handleZoom"
                            activeMode={mode}
                            brushRadius={brushRadius}
                            cursorStyle={cursorStyle}
                            trashRadius={trashRadius}
                            brushColor={brushColor}
                            imgSrc={null}
                            zoomMarkers={null}
                            isActive={true}
                            setPreviewCount={(index) => {
                                changePreviewPage(index);
                            }}
                            newPreviewCoords={newPreviewCoords}
                            questions={questions}
                            // ref={drawPanelRef}
                        />
                    </div>
                ) : null}
            </div>
            <div
                className="drawing-board"
                ref={areaInput}
                style={{
                    display : 'block',
                    backgroundImage : `url("${background}")`,
                    backgroundPosition : 'center center',
                    backgroundSize : '100% 100%',
                    backgroundRepeat : 'no-repeat'
                }}
            >

                {
                    data.length > 0 &&
                    <DrawPanel
                        currentPage={currentPage}
                        preview={false}
                        key={`page-${currentPage}`}
                        activeMode={mode}
                        brushRadius={brushRadius}
                        trashRadius={trashRadius}
                        brushColor={brushColor}
                        imgSrc={renderPage?.src}
                        zoomMarkers={renderPage?.zoomMarkers}
                        isActive={true}
                        onZoomMarker={handeZoom}
                        pageStatus={pageStatus}
                        setPreviewCount={(index) => {
                            changePreviewPage(index);
                        }}
                        newPreviewCoords={newPreviewCoords}
                        ref={(ref) => (currentRef = ref)}
                        questions={questions}
                        cursorStyle={cursorStyle}
                    />
                }
            </div>
        </div>
    );
};

export default BoardScreen;
